import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { GET_CLIENTS, GET_CLIENT, LOGOUT } from "./types";


export const table = (page, size, past, active, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/l/p/${page}/s/${size}/p/${past}/a/${active}/o/${order}/${direction}/${search}`);

        dispatch({
            type: GET_CLIENTS,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/clients`);

        if (res?.data) {
            dispatch({
                type: GET_CLIENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/clients/i/${uuid}`);

        if (res?.data) {
            dispatch({
                type: GET_CLIENT,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};


export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/clients`, data);

        if (res?.data?.success) {
            for (let i = 0; i < data.Matters?.length || 0; i++) {
                const item = data.Matters[i];
                item.res = await api.post(`/clients/${res.data.uuid}/matters`, item);
            }
            dispatch(sendAlert("Client updated.", "success"));
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const clocks = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/clocks/c/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const payments = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/payments/c/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const trusts = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/trusts/c/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const expenses = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/expenses/c/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const invoices = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/invoices/c/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};


export const summary = (uuid) => async(dispatch) => {
    try {
        const res = await api.get(`/clients/s/${uuid}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}