import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../../layout/table";

import { format as formatDate } from "../../../../utils/date";
import { deepEqual } from "../../../../utils/object";

class Timecards extends React.Component {

    get pageSize() {
        return 5;
    }

    get pages() {
        return Math.ceil(this.props.clocks?.length / 5);
    }

    clocks = (current, size=this.pageSize) => {
        const result = [];
        for (let i = this.pageSize * current; i < Math.min(this.props.clocks?.length, this.pageSize * (current+1)); i++) {
            const __clock = this.props.clocks[i];
            result.push({
                uuid: __clock.uuid,
                clockStarted: __clock.clockStarted,
                clockEnded: __clock.clockEnded,
                minutes: __clock.minutes,
                breaks: __clock.breaks,
                employeeUuid: __clock.employeeUuid,
                matterUuid: __clock.matterUuid,
                notes: __clock.notes,
                rate: __clock.rate,
                charge: __clock.charge,
                lastName: __clock.Person.lastName,
                firstName: __clock.Person.firstName,
                title: __clock.Employee.title,
            }); 
        }
        return result;
    }

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: this.pageSize,
            search: "",
            order: "clockStarted",
            index: 0,
            direction: "DESC",
            data: this.clocks(0),
            pages: this.pages,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.matters, this.props?.matters)) {
            this.setState({
                ...this.state,
                data: this.clocks(0),
                page: 0,
                pages: this.pages
            });
        }
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "clockStarted",
                className: "text-muted text-small text-uppercase w-15",
                onRender: this.onDateLinkFormat
            },
            {
                header: "Staff",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-45",
                onFormat: this.onEmployeeFormat
            },
            {
                header: "Time",
                sortable: true,
                source: "minutes",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onTimeFormat
            }
        ]
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect(e.target.attributes["uuid"].value);
    }


    onDateLinkFormat = (e, row) => {
        return this.onLinkFormat(formatDate(e), row);
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()} onClick={this.onItemClick} href="#" role="link">{e}</a>
    }

    onEmployeeFormat = (e, row) => {
        return `${row["lastName"]}, ${row["firstName"]} (${row["title"]})`;
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e),
            data: this.clocks(Number(e)),
        }, this.fetchData);
    }


    render() {
        return (
            <>
                <Col xs="12">
                    <h2 className="small-title">Billable Time</h2>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Timecards.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps)(Timecards);