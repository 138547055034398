import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../../layout/table";

import { format as formatDate } from "../../../../utils/date";
import { deepEqual } from "../../../../utils/object";
import { list as getCodes } from "../../../../actions/code";
import { currency as formatCurrency } from "../../../../utils/number";
import { expenses as getExpenses } from "../../../../actions/matter";

class Expenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "expenseDate",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.matter, this.props?.matter)) {
            this.fetchData();
        } else if (!deepEqual(old?.updated, this.props?.updated)) {
            this.fetchData();
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }


    fetchData = async () => {
        setTimeout( async () => {

            const __data = await this.props.getCodes("ET");
            const __expenses = await this.props.getExpenses(this.props.matter, this.state.page, this.state.size, this.state.order, this.state.direction);

            this.setState({
                ...this.state,
                sources: __data,
                data: __expenses
            });
        }, 30);
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "expenseDate",
                className: "text-muted text-small text-uppercase w-15",
                onRender: this.onDateLinkFormat
            },
            {
                header: "Reference",
                sortable: true,
                source: "notes",
                className: "text-muted text-small text-uppercase w-45",
                onRender: this.onReferenceLinkFormat
            },
            {
                header: "Amount",
                sortable: true,
                source: "charge",
                className: "text-muted text-small text-uppercase w-10 align-right",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect({
            uuid: e.target.attributes["uuid"].value,
            matterUuid: e.target.attributes["matter"].value,
            employeeUuid: e.target.attributes["employee"].value,
            notes: e.target.attributes["notes"].value,
            merchant: e.target.attributes["merchant"].value,
            charge: e.target.attributes["charge"].value,
            expenseDate: e.target.attributes["date"].value,
            category: e.target.attributes["category"].value,
        });
    }

    getSource = (value) => {
        let __result = this.state.sources.find(obj => {
            return obj.value === value;
        });
        return (__result ? __result.description : "");
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number(e));
    }

    onDateFormat = (e) => {
        return formatDate(e, true);
    }

    onDateLinkFormat = (e, row) => {
        return this.onLinkFormat(formatDate(e), row);
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()}
            matter={row.matterUuid.toString()}
            employee={row.employeeUuid.toString()}
            notes={row.notes.toString()}
            merchant={row.merchant.toString()}
            charge={row.charge.toString()}
            date={row.expenseDate.toString()}
            category={row.category.toString()}
            onClick={this.onItemClick}
            href="#"
            role="link">{e}</a>
    }

    onEmployeeFormat = (e, row) => {
        return `${row["Person.lastName"]}, ${row["Person.firstName"]} (${row["Employee.title"]})`;
    }

    onReferenceLinkFormat = (e, row) => {
        return this.onLinkFormat(`${e}`, row);
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }


    render() {
        return (
            <>
                <Col xs="12">
                    <h2 className="small-title mt-5">Expenses</h2>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Expenses.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updated: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        updated: state.client.expense
    });
};

export default connect(mapStateToProps, { getCodes, getExpenses })(Expenses);