import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap"
import PropTypes from "prop-types";

import { deepEqual } from "../../../../utils/object";
import { isGuid } from "../../../../utils/string";

import CourtSelect, { exists as CourtExists } from "../../../../layout/select/court-list";

import 'react-intl-tel-input/dist/main.css';
import 'react-datepicker/dist/react-datepicker.css';

class Profile extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            description: this.props.description || "",
            courtUuid: this.props.courtUuid || "",
            caseNumber: this.props.caseNumber || ""
        };
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                });
            }
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    onCourtChange = (e) => {
        if (isGuid(e?.value)) {
            this.onChange({
                target: {
                    name: "courtUuid",
                    value: e?.value
                }
            });
        }
    }


    render() {
        return (
            <Row className="g-3">
                <Col xs="12">
                    <h2 className="small-title">Matter</h2>
                </Col>
                <Col lg="12">
                    <div className="top-label">
                        <Form.Control type="text" name="description" value={this.state.description || ""} onChange={this.onChange} />
                        <Form.Label>DESCRIPTION</Form.Label>
                    </div>
                </Col>
                <Col lg="8">
                    <div className="top-label">
                        <CourtSelect value={this.state?.courtUuid || ""} onChange={this.onCourtChange} courts={this.props.courts} placeholder="" name="courtUuid" />
                        <Form.Label>Court</Form.Label>
                    </div>
                </Col>
                <Col lg="4">
                    <div className="top-label">
                        <Form.Control type="text" name="caseNumber" value={this.state?.caseNumber || ""} onChange={this.onChange} />
                        <Form.Label>Case #</Form.Label>
                    </div>
                </Col>
            </Row>
        )
    }
}

Profile.propTypes = {
    fields: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Profile);

