import api from "../utils/api";

/// api/files/upload/p/r/:referenceUuid
export const update = ({ referenceUuid, path, uuid }) => async (dispatch) => {
    try {
        const res = await api.post(`/files/directory`, { uuid, referenceUuid, path });
        return res.data;
    } catch (err) {
        console.log(err);
    }
} 

export const contents = ({ referenceUuid, path }) => async (dispatch) => {
    try {
        const res = await api.get(`/files/directory/r/${referenceUuid}?path=${path}`);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}