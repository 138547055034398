import { GET_EMPLOYEE } from "../actions/types";

const initialState = {
    employee: null,
    person: null
};

function personReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_EMPLOYEE:
            return {
                ...state,
                employee: payload,
                person: payload.Person,
                loading: false,
            };
        default:
            return state;
    }
}

export default personReducer;
