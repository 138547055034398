import React, { Fragment } from 'react';
import complexity from "./complexity/";

import Item from './Item';


class PasswordBar extends React.Component
{
    descStyle = {
        margin: '5px 0 0',
        color: '#898792',
        fontSize: 14,
        textAlign: 'right',
    }

    spaceStyle = {
        width: 4,
    }

    wrapStyle = {
        display: 'flex',
        alignItems: 'center',
        margin: '5px 0 0',
    }

    rootStyle = {
        position: 'relative',
    }

    static defaultProps =
        {
            className: undefined,
            style: undefined,
            scoreWordClassName: undefined,
            scoreWordStyle: undefined,
            password: '',
            barColors: [ '#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281' ],
            scoreWords: [ 'weak', 'weak', 'okay', 'good', 'strong' ],
            minLength: 4,
            shortScoreWord: 'too short',
            score: 0,
            upper: 1,
            lower: 1,
            number: 1,
            symbol: 0,
            onChangeScore: undefined
        }

    componentDidMount ()
    {
        this.setScore();
    }

    componentDidUpdate ( prevProps )
    {
        if ( prevProps.password !== this.props?.password )
        {
            this.setScore();
        }
    }

    setScore ()
    {
        const { password, minLength, onChangeScore, upper, lower, number, symbol } = this.props;
        let result = null;
        let score = 0;
        let feedback = "";
        if ( password.length >= minLength )
        {
            result = complexity( password, { upper, lower, number, symbol } );
            if (result) ( { score, feedback } = result );
        }
        if ( onChangeScore )
        {
            onChangeScore( score, feedback );
        }
    };

    render ()
    {
        const {
            className,
            style,
            scoreWordClassName,
            scoreWordStyle,
            password,
            barColors,
            scoreWords,
            minLength,
            shortScoreWord,
            score,
        } = this.props;
        
        const newShortScoreWord =
            password.length >= minLength ? scoreWords[ score ] : shortScoreWord;

        return (
            <div className={className} style={{ ...this.rootStyle, ...style }}>
                <div style={this.wrapStyle}>
                    {[ 1, 2, 3, 4 ].map( ( el ) => (
                        <Fragment key={`password-strength-bar-item-${ el }`}>
                            {el > 1 && <div style={this.spaceStyle} />}
                            <Item score={score} itemNum={el} barColors={barColors} />
                        </Fragment>
                    ) )}
                </div>
                <p
                    className={scoreWordClassName}
                    style={{ ...this.descStyle, ...scoreWordStyle }}
                >
                    {newShortScoreWord}
                </p>
            </div>
        );
    }
    
}

export default PasswordBar;