import React from "react";
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import Dropzone from "../dropzone";
import Select from "react-select";

import { isGuid } from "../../utils/string";

class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            referenceUuid: this.props.referenceUuid || "",
            category: this.props.category || "General"
        }
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if ((old?.referenceUuid !== this.props.referenceUuid) || (old?.category !== this.props.category)) {
            this.setState({
                referenceUuid: this.props.referenceUuid,
                category: this.props.category
            })
        }
    }

    onHiddenChange = (e) => {
        this.setState({
            ...this.state,
            category: "hidden"
        });
    }

    onReferenceChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ referenceUuid: e.value, category: e.category }, this.postChange);
        }
    }

    onFileUpload = (file) => {
        this.setState({
            ...this.state,
            file: { ...file }
        })
    }

    get fileUploaded() {
        return (this.state.file && isGuid(this.state.file.uuid));
    }

    onCancel = async (e) => {
        if (this.fileUploaded) {
            if (this.props?.removeFile) await this.props.removeFile(this.props.marriageUuid, this.state.file.uuid);
        }
        this.props?.onCancel(e, this.state);
    }

    onOk = (e) => {
        this.props.onOk(e, this.state);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(this.props.body) ? (
                        <Row className="g-3 pb-3 mt-1">
                            <Col xs="12">{this.props.body}</Col>
                        </Row>
                    ) : ``}
                    
                    {(this.props.references) ? (
                        <Row className="g-3 pb-3 mt-1">
                            <Col xs="12" md="10">
                                <Select
                                    id="references"
                                    value={this.props.references.filter(option => option.value === this.state.referenceUuid)}
                                    name="references"
                                    onChange={this.onReferenceChange}
                                    classNamePrefix="react-select"
                                    placeholder="Refers To"
                                    options={this.props.references}
                                >
                                </Select>
                            </Col>
                            <Col md="2" style={{ marginTop: "22px" }}>
                                <Form.Check type="checkbox" label="Internal" value="1" id="internal" inline name="hidden" checked={this.state?.category === "hidden"} onChange={this.onHiddenChange} />
                            </Col>
                        </Row>
                    ) : ``}
                    {(isGuid(this.state.referenceUuid) && (this.state.category)) ? (
                        <Dropzone label="Upload file" url={ this.props.url } referenceUuid={this.state.referenceUuid} category={this.state.category} onFileUpload={ this.onFileUpload } />
                    ) : (this.props.onGetUrl) ? (
                        <Dropzone label="Upload file" onGetUrl={this.props.onGetUrl} onFileUpload={ this.onFileUpload } />
                    ) : ``}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.onCancel}>cancel</Button>
                    <Button variant={this.fileUploaded ? "primary" : "muted"} onClick={this.fileUploaded ? this.onOk : null}>ok</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ExModal;