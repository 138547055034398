import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import NumberFormat from "react-number-format";

import CodeSelect from "../select/code";
import ConditionalButton from "../conditional-button";
import { deepEqual } from "../../utils/object";

import DatePickerEx from "../date-picker";


class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {

        let __working = {
            ...this.props
        }

        return {
            show: __working.show,
            source: __working.source || "OT",
            amount: __working.amount || 0.0,
            reference: __working.reference || "",
            uuid: __working.uuid,
            date: __working.date || Date.now(),
            recorder: __working.recorder
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }

    onSourceChange = (e) => {
        this.onChange({
            target: {
                name: "source",
                value: e.value
            }
        })
    }

    onDateChange = (e) => {
        this.setState({
            ...this.state,
            date: new Date(e)
        });
    }

    onAmountChange = (e) => {
        this.onChange({ target: { name: "amount", value: e.floatValue } });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = (e) => {
        this.props.onOk({
            uuid: this.state.uuid,
            recordedByUuid:  this.state.recorder || this.props.auth.user.uuid,
            amount: this.state.amount,
            trust: this.state.amount,
            reference: this.state.reference,
            paymentDate: this.state.date,
            paymentSource: this.state.source
        });
    }

    get canSave() {
        return this.state.reference?.length > 1 && this.state.source?.length==2 && this.state.amount > 0;
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Payment`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12" md="4">
                            <div className="top-label">
                                <DatePickerEx
                                    id="date"
                                    selected={this.state.date}
                                    name="date"
                                    onChange={this.onDateChange}
                                    className="text-end form-control"
                                    dateFormat="MM/dd/yyyy"
                                />
                                <Form.Label>DATE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="8">
                            <div className="top-label">
                                <CodeSelect codes={this.props.sources} value={this.state.source || "OT"} onChange={this.onSourceChange} placeholder="" name="source" />
                                <Form.Label>SOURCE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="top-label">
                                <NumberFormat
                                    displayType="input"
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    className="text-end form-control mb-2"
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    value={this.state.amount || 0}
                                    onValueChange={this.onAmountChange}
                                    removeFormatting
                                />
                                <Form.Label>AMOUNT</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="8">
                            <div className="top-label">
                                <Form.Control type="text" name="reference" value={this.state.reference || ""} onChange={this.onChange} />
                                <Form.Label>REFERENCE</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);