import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

import ConditionalButton from "../../layout/conditional-button";
import { deepEqual } from "../../utils/object";
import Personal from "./person";

import { get as getEmployee, save as saveEmployee } from "../../actions/employee";
import { pack as setAlerts } from "../../actions/alert";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            person: {}
        };
        
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.person, this.props.person) && !deepEqual(this.props.person, this.state.person)) {
            this.setState({
                person: this.props.person
            });
        }
    }

    componentDidMount = async () => {
        await this.props.getEmployee();
    }

    onPersonChange = (value, errors) => {
        this.setState({
            ...this.state,
            person: {
                ...value
            },
            errors: errors
        });
    }

    onSaveClick = async (e) => {
        const result = await this.props.saveEmployee({ uuid: this.state.person.uuid, Person: this.state.person });
        if (result.success) {
            this.setState({
                person: result.person
            })
        }
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Save my information" display={true} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                </Col>
                <Col md="12">
                    <Form name="frm" action="post" onSubmit={this.onSubmit} >
                        <Row>
                            <Col>
                                <Card body className="mb-5">
                                    <Personal {...this.state.person} onChange={this.onPersonChange} />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    person: PropTypes.object,
    employee: PropTypes.object,
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        fields: state.fields.items,
        person: state.person.person,
        employee: state.person.employee
    });
};

export default connect(mapStateToProps, { getEmployee, saveEmployee, setAlerts })(Profile);
