import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import Select from "react-select";
import NumberFormat from "react-number-format";

import ConditionalButton from "../conditional-button";
import { isGuid } from "../../utils/string";
import { deepEqual } from "../../utils/object";
import { minutesBetween, addMinutes } from "../../utils/date";

import DatePickerEx from "../date-picker";


class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        let __working = {
            ...this.props
        }

        return {
            show: __working.show,
            payee: __working.payee || "",
            number: __working.number || 0,
            amount: __working.amount || 0,
            date: __working.date || Date.now(),
            memo: __working.memo || "",
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }

    onDateChange = (e) => {
        this.onChange({
            target: {
                name: "date",
                value: new Date(e)
            }
        });
    }

    onAmountChange = (e) => {
        this.onChange({ target: { name: "amount", value: e.floatValue } });
    }

    onNumberChange = (e) => {
        this.onChange({ target: { name: "number", value: e.floatValue } });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = (e) => {
        this.props.onOk({
            uuid: this.props.uuid,
            payee: this.state.payee,
            number: this.state.number,
            amount: this.state.amount,
            date: this.state.date,
            memo: this.state.memo
        });
    }

    get canSave() {
        return  this.state.payee?.length > 1 && this.state.number > 0 && this.state.amount > 0;
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Check`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12" md="6" lg="4">
                            <div className="top-label">
                                <NumberFormat
                                    displayType="input"
                                    type="text"
                                    id="number"
                                    name="number"
                                    className="text-end form-control mb-2"
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    value={this.state.number || 0}
                                    onValueChange={this.onNumberChange}
                                    removeFormatting
                                />
                                <Form.Label>NUMBER</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="6" lg="4">
                            <div className="top-label">
                                <DatePickerEx
                                    id="date"
                                    selected={this.state.date}
                                    name="date"
                                    onChange={this.onDateChange}
                                    className="text-end form-control"
                                    dateFormat="MM/dd/yyyy"
                                />
                                <Form.Label>DATE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" lg="4">
                            <div className="top-label">
                                <NumberFormat
                                    displayType="input"
                                    type="text"
                                    id="amount"
                                    name="amount"
                                    className="text-end form-control mb-2"
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    value={this.state.amount || 0}
                                    onValueChange={this.onAmountChange}
                                    removeFormatting
                                />
                                <Form.Label>AMOUNT</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="payee" value={this.state.payee || ""} onChange={this.onChange} />
                                <Form.Label>PAYEE</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="memo" value={this.state.memo || ""} onChange={this.onChange} />
                                <Form.Label>MEMO</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);