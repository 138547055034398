import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import Select from "react-select";
import NumberFormat from "react-number-format";
import { isMobile } from 'react-device-detect';

import ConditionalButton from "../../layout/conditional-button";
import { isGuid } from "../../utils/string";
import { deepEqual } from "../../utils/object";
import { minutesBetween, addMinutes } from "../../utils/date";

import DatePickerEx from "../date-picker";


class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        const matteroptions = [];
        for (let i = 0; i < this.props.matters.length; i++) {
            const item = this.props.matters[i];
            matteroptions.push(
                { value: item.uuid, label: item.description, object: item }
            )
        }

        let __working = {
            ...this.props
        }

        if (this.props.matters && isGuid(this.props.uuids.matter) && !this.props.selected?.matter) {
            let __matter = this.props.matters.find(obj => {
                return obj.uuid === this.props.uuids.matter;
            });
            
            __working.selected = {
                ...__working.selected,
                matter: __matter
            }
        }

        const employeeoptions = [];
        for (let i = 0; i < __working.selected?.matter?.MatterEmployees.length; i++) {
            const item = __working.selected?.matter?.MatterEmployees[i];
            employeeoptions.push(
                { value: item.Employee.uuid, label: `${item.Employee.Person.firstName} ${item.Employee.Person.lastName} (${item.Employee.title})`, object: item }
            )
        }


        return {
            show: __working.show,
            matters: __working.matters || [],
            selected: {
                matter: __working.selected?.matter,
                uuids: {
                    client: __working.uuids?.client,
                    matter: __working.uuids?.matter,
                    employee: __working.uuids?.employee,
                    clock: __working.uuid
                },
                rate: __working.selected?.rate || 0,
                charge: __working.selected?.charge || 0,
                interval: __working.selected?.matter?.interval || 6
            },
            clock: {
                started: __working.clock?.started || Date.now(),
                ended: __working.clock?.ended || Date.now(),
                minutes: __working.clock?.minutes || 0
            },
            options: {
                matters: matteroptions,
                employees: employeeoptions
            },
            notes: __working.notes || ""
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.matters, this.props.matters)) {
            this.setState(this.propsToState);
        } else if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }

    onMatterChange = (e) => {
        const employeeoptions = [];
        for (let i = 0; i < e.object.MatterEmployees.length; i++) {
            const item = e.object.MatterEmployees[i];
            employeeoptions.push(
                { value: item.Employee.uuid, label: `${item.Employee.Person.firstName} ${item.Employee.Person.lastName} (${item.Employee.title})`, object: item }
            )
        }

        if (isGuid(e?.value)) {
            this.setState({
                ...this.state,
                options: {
                    ...this.state.options,
                    employees: employeeoptions
                },
                selected: {
                    ...this.state.selected,
                    matter: e.object,
                    uuids: {
                        ...this.state.selected.uuids,
                        matter: e?.value
                    },
                    interval: e.object.interval
                }
            });
        }
    }

    intervalCeiling = (interval, minutes) => {
        return Math.ceil(minutes / interval) * interval;
    }

    calculateCharge = () => {
        this.setState({
            ...this.state,
            selected: {
                ...this.state.selected,
                charge: this.intervalCeiling(this.state.selected.interval, this.state.clock.minutes) * (this.state.selected.rate / 60)
            }
        })
    }

    onEmployeeChange = (e) => {
        if (isGuid(e?.value)) {
            this.setState({
                ...this.state,
                selected: {
                    ...this.state.selected,
                    uuids: {
                        ...this.state.selected.uuids,
                        employee: e?.value
                    },
                    rate: e.object.rate
                }
            }, this.calculateCharge);
        }
    }

    onMinutesChange = (e) => {
        const __date = addMinutes(this.state.clock.started, e.floatValue);
        this.setState({
            ...this.state,
            clock: {
                ...this.state.clock,
                ended: __date,
                minutes: e.floatValue
            }
        }, this.calculateCharge)
    }

    onClockStartedChange = (e) => {
        this.setState({
            ...this.state,
            clock: {
                ...this.state.clock,
                started: new Date(e),
                minutes: minutesBetween(new Date(e), new Date(this.state.clock.ended))
            }
        }, this.calculateCharge);
    }

    onClockEndedChange = (e) => {
        this.setState({
            ...this.state,
            clock: {
                ...this.state.clock,
                ended: new Date(e),
                minutes: minutesBetween(new Date(this.state.clock.started), new Date(e))
            }
        }, this.calculateCharge);
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = (e) => {
        this.props.onOk({
            uuid: this.state.selected.uuids.clock,
            clientUuid: this.state.selected.uuids.client,
            matterUuid: this.state.selected.uuids.matter,
            employeeUuid: this.state.selected.uuids.employee,
            clockStarted: this.state.clock.started,
            clockEnded: this.state.clock.ended,
            minutes: this.state.clock.minutes,
            notes: this.state.notes,
            rate: this.state.selected.rate,
            charge: this.state.selected.charge
        });
    }

    get canSave() {
        return this.state.notes.length > 1 && isGuid(this.state.selected.uuids.matter) && isGuid(this.state.selected.uuids.employee) && (this.state.clock.minutes > 0);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size={ isMobile ? "sm" : "lg"} backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Billable Time`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12">
                            <div className="top-label">
                                <Select
                                    id="matterUuid"
                                    value={this.state.options.matters?.filter(option => option.value === this.state.selected?.uuids?.matter)}
                                    name="matterUuid"
                                    onChange={this.onMatterChange}
                                    classNamePrefix="react-select"
                                    placeholder="Matter"
                                    options={this.state.options.matters}
                                >
                                </Select>
                                <Form.Label>MATTER</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Select
                                    id="employeeUuid"
                                    value={this.state.options.employees?.filter(option => option.value === this.state.selected?.uuids?.employee)}
                                    name="employeeUuid"
                                    onChange={this.onEmployeeChange}
                                    classNamePrefix="react-select"
                                    placeholder="Employee"
                                    options={this.state.options.employees}
                                >
                                </Select>
                                <Form.Label>STAFF MEMBER</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="top-label">
                                <DatePickerEx
                                    id="started"
                                    selected={this.state.clock?.started}
                                    name="started"
                                    onChange={this.onClockStartedChange}
                                    className="form-control"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                />
                                <Form.Label>FROM</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="top-label">
                                <DatePickerEx
                                    id="ended"
                                    selected={this.state.clock?.ended}
                                    name="ended"
                                    onChange={this.onClockEndedChange}
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    timeInputLabel="Time:"
                                    showTimeInput
                                    className="form-control"
                                />
                                <Form.Label>TO</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="4">
                            <div className="top-label">
                                <NumberFormat
                                    displayType="input"
                                    type="text"
                                    id="minutes"
                                    name="minutes"
                                    className="text-right form-control mb-2"
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    value={this.state.clock?.minutes || 0}
                                    onValueChange={this.onMinutesChange}
                                    removeFormatting
                                />
                                <Form.Label>MINUTES</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="notes" value={this.state.notes || ""} onChange={this.onChange} />
                                <Form.Label>NOTES</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);