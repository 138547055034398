import React from 'react';
import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { url } from '../../utils/api';

class DropzoneImages extends React.Component {
    constructor(props) {
        super(props);
    }

    getUploadParams = ({ file, meta }) => {
        return {
            url: `${url()}/${this.props.url}` || `${url()}/files/upload/r/${this.props.referenceUuid}/c/${this.props.category}`,
            headers: {
                'x-auth-token': localStorage.token
            }
        };
    };

    onChangeStatus = (fileWithMeta, status) => {
        if ((status === "done") && (this.props.onFileUpload)) {
            this.props.onFileUpload(JSON.parse(fileWithMeta.xhr.response));
            if (this.props.autoRemove === true) {
                fileWithMeta.remove();
            }
        }
    };


    get maxSize() {
        return 1048576 * 2;
    }

    render() {

        return (
            <Dropzone
                getUploadParams={this.getUploadParams}
                //PreviewComponent={DropzonePreview}
                submitButtonContent={null}
                accept={this.props.accept}
                submitButtonDisabled
                SubmitButtonComponent={null}
                inputWithFilesContent={null}
                maxSizeBytes={this.maxSize}
                onChangeStatus={this.onChangeStatus}
                classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
                inputContent={this.props.label}
            ></Dropzone>
        );
    }
};

export default DropzoneImages;
