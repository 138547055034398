import { v4 as uuidv4 }  from 'uuid';

import { isGuid } from "../string";

export class item {
    constructor() {
        this.net = 0.0;
        this.adjustment = 0.0;
        this.gross = 0.0;
        this.type = "UN";
        this.description = "";
        this.date = Date.now();
        this.uuid = uuidv4();
        this.source = null;
    }

    load = function (row) {
        const { date, net, adjustment, gross, itemType, description, uuid, source } = row;
        this.net = Number(net);
        this.adjustment = Number(adjustment);
        this.gross = Number(gross);
        this.type = itemType;
        this.description = description;
        this.date = date;
        this.uuid = isGuid(uuid) ? uuid : this.uuid;
        this.source = source;

        return this;
    }   
} 