import api from "../utils/api";

import {
  LOGOUT, GET_INTEGRATIONS
} from "./types";

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/integrations`);

        if (res?.data) {
            dispatch({
                type: GET_INTEGRATIONS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const perform = (integrationUuid, clientUuid) => async (dispatch) => {
    try {
        const resIntegration = await api.get(`/integrations/i/${integrationUuid}`);
        const __integration = resIntegration.data[0];
        const __settings = __integration.IntegrationSettings[0];

        const __export = {
            uuid: clientUuid,
            url: __settings.url,
            host: __settings.host,
            apiKey: __settings.apiKey,
            ipAddress: __settings.ipAddress,
            contentType: __settings.contentType,
            meta: __settings.meta,
            matterType: __integration.matterTypes,
            integrationUuid: __integration.uuid
        };
        const resClient = await api.post(`/clients/export/${__integration.name}`, __export);
        return resClient.data;

    } catch (err) {
        return {
            success: false
        }
    }
};