import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";

import AttorneySelect, { exists as AttorneyExists } from "../../../../layout/select/attorney-list";
import CodeSelect from "../../../../layout/select/code";
import { deepEqual } from "../../../../utils/object";
import { format as formatDate } from "../../../../utils/date";
import { isGuid } from "../../../../utils/string";

import 'react-intl-tel-input/dist/main.css';
import 'react-datepicker/dist/react-datepicker.css';

class Case extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            attorneyUuid: isGuid(this.props.attorneyUuid) ? this.props.attorneyUuid : null,
            representationStarted: this.props.representationStarted || Date.now(),
            representationEnded: this.props.representationEnded,
            attorneys: this.props.attorneys || [],
            parties: this.props.parties || [],
            types: this.props.types || [],
            results: this.props.results || [],
            interval: this.props.interval || 6,
            typeCode: this.props.typeCode || "OT",
            partyCode: this.props.partyCode || "O",
            resultCode: this.props.resultCode || "IP",
            uuid: isGuid(this.props.uuid) ? this.props.uuid : uuidv4()
        };
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                });
            }
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
        this.props.onChange(e);
    }

    onAttorneyChange = (e) => {
        if (isGuid(e?.value)) {
            this.onChange({
                target: {
                    name: "attorneyUuid",
                    value: e?.value
                }
            })
        }
    }

    onTypeChange = (e) => {
        this.onChange({
            target: {
                name: "typeCode",
                value: e.value
            }
        })
    }

    onPartyChange = (e) => {
        this.onChange({
            target: {
                name: "partyCode",
                value: e.value
            }
        })
    }

    onResultChange = (e) => {
        this.onChange({
            target: {
                name: "resultCode",
                value: e.value
            }
        })
    }

    render() {
        return (
            <Row>
                <Col lg="12"><p>Representation started {formatDate(this.props.representationStarted)}{
                    this.props.representationEnded ?
                        ` ended ${formatDate(this.props.representationEnded)}` :
                        ``
                }
                    <span className={`badge rounded-pill bg-${this.props.representationEnded ? "danger" : "success"}`} style={{ marginLeft: "5px" }}>{this.props.representationEnded ? "PAST" : "CURRENT"}</span></p>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <AttorneySelect attorneys={this.props.attorneys} value={this.state.attorneyUuid || ""} onChange={this.onAttorneyChange} placeholder="" name="attorneyUuid" />
                        <Form.Label>ATTORNEY IN CHARGE</Form.Label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <CodeSelect codes={this.props.types} value={this.state.typeCode || "OT"} onChange={this.onTypeChange} placeholder="" name="typeCode" />
                        <Form.Label>CASE TYPE</Form.Label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <CodeSelect codes={this.props.parties} value={this.state.partyCode || "O"} onChange={this.onPartyChange} placeholder="" name="partyCode" />
                        <Form.Label>PARTY TYPE</Form.Label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <CodeSelect codes={this.props.results} value={this.state.resultCode || "IP"} onChange={this.onResultChange} placeholder="" name="resultCode" />
                        <Form.Label>STATUS</Form.Label>
                    </div>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <NumberFormat
                            displayType="input"
                            type="text"
                            id="interval"
                            name="interval"
                            className="text-right form-control mb-2"
                            thousandSeparator={true}
                            allowNegative={false}
                            value={this.state.interval || 6}
                            onValueChange={(e) =>
                                this.onChange({
                                    target: { name: "interval", value: e.floatValue },
                                })
                            }
                            removeFormatting
                        />
                        <Form.Label>CHARGING INTERVAL (MINUTES)</Form.Label>
                    </div>
                </Col>
            </Row>
        )
    }
}

Case.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Case);

