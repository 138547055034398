import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { GET_LEADS, GET_LEAD, LOGOUT } from "./types";


export const table = (page, size, order, direction, converted, archived, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/leads/l/p/${page}/s/${size}/o/${order}/${direction}/c/${converted}/a/${archived}/${search}`);

        dispatch({
            type: GET_LEADS,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/leads`);

        if (res?.data) {
            dispatch({
                type: GET_LEADS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/leads/i/${uuid}`);

        if (res?.data) {
            dispatch({
                type: GET_LEAD,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};


export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/leads`, data);

        if (res?.data?.success) {
            dispatch(sendAlert("Lead updated.", "success"));
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const convert = ({ uuid, trustAmount, sourceCategory }) => async (dispatch) => {
    try {
        const res = await api.post(`/clients`, {
            uuid,
            sourceCode: sourceCategory,
            representationStarted: Date.now(),
            earnings: 0,
            trust: 0,
            balance: 0,
            sourceFees: 0
        });

        if (res?.data?.success) {
            dispatch(sendAlert("Client converted.", "success"));
        }

        const resTrust = await api.post(`/trusts`, {
            uuid,
            clientUuid: uuid,
            name: "Main",
            opened: Date.now(),
            initialValue: 0,
            currentValue: 0,
            minimumValue: trustAmount
        });

        if (resTrust?.data?.success) {
            dispatch(sendAlert("Trust account created.", "success"));
        }

        const resLead = await api.post('/leads', {
            uuid,
            convertDate: Date.now()
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}


