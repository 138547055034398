import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { v4 as uuidv4 } from "uuid";
import { LOGOUT } from "./types";

export const expenses = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/expenses/m/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const parties = (uuid, page, size, order, direction, search="") => async (dispatch) => {
    try {
        const res = await api.get(`/clients/parties/m/${uuid}/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};