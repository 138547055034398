import { v4 as uuidv4 } from "uuid";
import { isGuid } from "../string";
import { daysBetween } from "../date";
import { item } from "./item";

export class invoice {
    constructor() {
        this.generated = Date.now();

        this.options = {
            sort: "date",
            direction: "DESC"
        };
            
        this.items = {
            services: [],
            payments: [],

            sort: function (options) {
                let _options = options ? options : this.parent.options;
                this.services.sort((a, b) => {
                    if (_options.sort === "date") {
                        let da = new Date(a.date),
                            db = new Date(b.date);
                        return _options.direction==="ASC" ? da - db : db - da;
                    } else {
                        return _options.direction==="ASC" ? a[_options.sort] - b[_options.sort] : b[_options.sort] - a[_options.sort];
                    }
                });
                this.payments.sort((a, b) => {
                    if (_options.sort === "date") {
                        let da = new Date(a.date),
                            db = new Date(b.date);
                        return _options.direction==="ASC" ? da - db : db - da;
                    } else {
                        return _options.direction==="ASC" ? a[_options.sort] - b[_options.sort] : b[_options.sort] - a[_options.sort];
                    }
                });
                return this;
            }
        };

        this.credit = {
            limit: 0.0,
            rate: 18.0,
            payment: 0.0,
            generated: 0.0,
            balance: 0.0
        };

        this.latest = {
            uuid: null,
            date: null,
            previous: 0.0,
            balance: 0.0
        };

        this.totals = {
            services: function (types) {
                let result = 0.0;
                this.parent.items.services.forEach(element => {
                    if (types) {
                        if (element.type === types)
                            result += element.net;
                    } else {
                        result += element.net;
                    }
                });
                return result;
            },
            payments: function () {
                let result = 0.0;
                this.parent.items.payments.forEach(element => {
                    result += element.net;
                });
                return result;
            },
            balance: function () {
                let __balance = this.parent.latest.balance + this.payments();
                __balance = __balance > 0 ? __balance : 0;
                return __balance;
            },
            subtotal: function () {
                return this.balance() + this.services();
            },
            interest: function () {
                const __rate = this.parent.credit.rate / 100 / 365;
                const __date = this.parent.latest?.date ? new Date(this.parent.latest.date) : new Date(Date.now());
                const __exp = Math.abs(daysBetween(__date, new Date(this.parent.generated)));
                const __multi = Math.pow(1 + __rate, __exp);
                const __total = this.balance() * __multi;
                return __total - this.balance();
            },
            net: function () {
                let __net = this.parent.latest.balance + this.services() - this.parent.trust.current + this.interest() - this.payments();
                return __net > 0 ? __net : 0;
            },
            overage: function () {
                let __available = this.parent.trust.current - this.services() - this.parent.latest.balance - this.interest();
                return __available > 0 ? 0 : -(__available);
            },
            due: function () {
                let __due = 0;
                if (this.parent.credit.limit > 0) {
                    __due = Math.min(this.net(), this.parent.credit.payment);
                } else {
                    __due = this.parent.trust.minimum - this.parent.trust.available() + this.overage();
                }
                return __due > 0 ? __due : 0;
            }

        };

        this.trust = {
            minimum: 0.0,
            current: 0.0,
            available: function () {
                let __available = this.current - this.parent.totals.services() - this.parent.latest.balance - this.parent.totals.interest();
                return __available < 0 ? 0 : __available;
            },
            applied: function () {
                let __applied = this.current - this.available()
                return __applied < 0 ? 0 : __applied;
            }
        };

        this.totals.parent = this;
        this.trust.parent = this;
        this.items.parent = this;
    }

    load = function (rows) {
        rows.forEach(element => {
            switch (element["itemType"]) {
                case "CL":
                case "EX":
                    this.items.services.push((new item()).load(element));
                    break;
                case "PY":
                    this.items.payments.push({ source: element["source"], uuid: element["uuid"], date: element["date"], description: element["description"], net: Number(element["net"]) });
                    break;
                case "XC":
                    this.credit.limit = Number(element["gross"]);
                    this.credit.rate = Number(element["adjustment"]);
                    this.credit.payment = Number(element["net"]);
                    break;
                case "XI":
                    this.latest.uuid = element["invoiceUuid"];
                    this.latest.date = element["date"];
                    this.latest.previous = Number(element["gross"]);
                    this.latest.balance = Number(element["net"]);
                    break;
                case "IT":
                    this.trust.minimum = Number(element["net"]);
                    this.trust.current = Number(element["adjustment"]);
                    break;
            }
            return;
        });
        this.items.sort(null);
        return this;
    }

    export = function () {
        return {
            generated: this.generated,
            uuid: isGuid(this.uuid) ? this.uuid : uuidv4(),
            items: {
                services: this.items.services,
                payments: this.items.payments
            },
            credit: {
                ...this.credit
            },
            latest: {
                ...this.latest
            },
            totals: {
                services: this.totals.services(),
                payments: this.totals.payments(),
                balance: this.totals.balance(),
                subtotal: this.totals.subtotal(),
                interest: this.totals.interest(),
                net: this.totals.net(),
                overage: this.totals.overage(),
                due: this.totals.due()
            },
            trust: {
                minimum: this.trust.minimum,
                current: this.trust.current,
                available: this.trust.available(),
                applied: this.trust.applied()
            }
        };
    }
}

