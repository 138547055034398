import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { v4 as uuidv4 } from "uuid";
import { LOGOUT, SET_EXPENSE } from "./types";

export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/clients/${data.clientUuid}/m/${data.matterUuid}/e/${data.employeeUuid}/expenses/${data.uuid || uuidv4()}`, data);

        if (res?.data) {
            dispatch({
                type: SET_EXPENSE,
                payload: res.data
            });
            return res?.data;
        }

    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

