import React from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../layout/table";
import { table as getChecks, get as getCheck, save as saveCheck } from "../../actions/check";
import { docx as generateDocx, pdf as generatePdf, xsl as generateXsl } from "../../actions/report";
import { format as formatDate } from "../../utils/date";
import { currency as formatCurrency, words } from "../../utils/number";
import { JavaScripttoXML } from "../../utils/serialize";

import ConditionalButton from "../../layout/conditional-button";
import Check from "../../layout/check";

class Checks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "updatedAt",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            modal: {
                check: {
                    show: false
                }
            },
        };
    }

    componentDidMount = async () => {
        this.fetchData();
    }

    fetchData = async () => {
        const __data = await this.props.getChecks(this.state.page, this.state.size, this.state.order, this.state.direction, this.state.search);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "checkDate",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onDateLinkFormat
            },
            {
                header: "#",
                sortable: true,
                source: "number",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onLinkFormat
            },
            {
                header: "Payee",
                sortable: true,
                source: "payee",
                className: "text-muted text-small text-uppercase w-60",
                onRender: this.onLinkFormat
            },
            {
                header: "Amount",
                sortable: true,
                source: "amount",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Updated",
                sortable: true,
                source: "updatedAt",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onDateFormat
            },
            {
                header: "",
                sortable: false,
                source: "uuid",
                className: "text-muted text-small text-uppercase w-20",
                onRender: this.onDownloadFormat
            }
        ]
    }

    onCheckDownload = async (uuid) => {
        //setTimeout(async () => {
        const __check = await this.props.getCheck(uuid);
        __check.words = words(Number(__check.amount), true, true);

        const __fileName = `${formatDate(__check.checkDate)} Check ${__check.number}.pdf`;
        const _xml = JavaScripttoXML(__check);
        const _result = await this.props.generateXsl(_xml, "cf6e52c0-d55e-11ed-a3e9-0efa9373c42b");

        const linkSource = `data:application/pdf;base64,${_result}`;
        const link = document.createElement("a");

        link.href = linkSource;
        link.setAttribute(
            'download',
            __fileName,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //}, 30);
        
    }

    onDownloadFormat = (e, row) => {
        return <>
            <a
                target="_blank"
                role="button"
                onClick={(e) => { this.onCheckDownload(row["uuid"]) }}
                className="badge rounded-pill bg-info"
                style={{ marginLeft: "5px", paddingLeft: "15px", paddingRight: "15px" }}>pdf</a>
        </>
    }

    onDateFormat = (e) => {
        return formatDate(e);
    }

    onDateLinkFormat = (e, row) => {
        return this.onLinkFormat(this.onDateFormat(e), row); //formatDate(e);
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number.parseFloat(e));
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()}
            onClick={this.onItemClick}
            href="#"
            role="link">{e}</a>
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    onCheckSelect = async (e) => {
        const __check = await this.props.getCheck(e);
        if (__check) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    check: {
                        ...this.state.modal.check,
                        selected: __check,
                        show: true
                    }
                }
            });
        }
    }

    onCheckOk = async (e) => {
        this.onCheckHideModal(e);
        e.subjectUuid = this.state.uuid;
        await this.props.saveCheck(e);

        await this.fetchData();
        this.setState({
            ...this.state,
            check: {
                ...this.state.check,
                updated: Date.now()
            }, 
            modal: {
                check: {
                }
            }
        });
    }
    
    onCheckCancel = async (e) => {
        this.onCheckHideModal(e);
        this.setState({
            ...this.state,
            modal: {
                check: {
                }
            }
        });
    }

    
    onCheckDelete = async (e,) => {
        this.onEventHideModal(e);
        await this.props.deleteCheck({ uuid: e.uuid });

        this.setState({
            ...this.state,
            check: {
                ...this.state.check,
                updated: Date.now()
            }, 
            modal: {
                check: {
                }
            }
        });
    }
    

    onCheckHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                check: {
                    ...this.state.modal.check,
                    show: false
                }
            }
        })
    }

    onRecordCheck = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                check: {
                    ...this.state.modal.check,
                    show: true
                }
            }
        })
    }

    onItemClick = (e) => {
        this.onCheckSelect(e.target.attributes["uuid"].value);
    }


    render() {
        return (
            <>
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="New check" display enabled={true} onEnabledClick={this.onRecordCheck} icon="edit-square">New Check</ConditionalButton>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                        onSearch={this.onSearch}
                        onSize={this.onSize}
                        searchable
                        sizable
                    />
                </Col>
                <Check
                    show={this.state.modal.check.show}
                    onCancel={this.onCheckCancel}
                    onOk={this.onCheckOk}
                    title={ this.state.modal.check?.selected?.number ? `Check # ${this.state.modal.check?.selected?.number}` : "New check"}
                    {...this.state.modal?.check?.selected}
                />
            </>
        )
    }
}

Checks.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getChecks, getCheck, saveCheck, generateXsl, generatePdf })(Checks);