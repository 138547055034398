import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row, Tab, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import ConditionalButton from "../../../layout/conditional-button";
import ConditionalDropdown from "../../../layout/conditional-dropdown";
import { isGuid } from "../../../utils/string";
import { format as formatDate } from "../../../utils/date";
import { JavaScripttoXML } from "../../../utils/serialize";
import { build, send } from "../../../utils/email";
import Logs from "../../../layout/logs";
import icalTk from "../../../utils/ics";

import { get as getClient, update as saveClient } from "../../../actions/client";
import { update as savePayment } from "../../../actions/payment";
import { update as saveClock } from "../../../actions/clock";
import { update as saveExpense } from "../../../actions/expense";
import { list as getLogs, update as saveLog } from "../../../actions/log";
import { list as getAttorneys } from "../../../actions/attorneys";
import { get as getInvoice, put as putInvoice } from "../../../actions/invoice";
import { list as getReports, get as getReport, docx as generateDocx, pdf as generatePdf, xsl as generateXsl, named } from "../../../actions/report";
import { list as getCodes, activities as getActivities } from "../../../actions/code";
import { pack as setAlerts } from "../../../actions/alert";
import { list as getIntegrations, perform as doIntegrate } from "../../../actions/integration";
import { save as saveEvent, client as getClientEvents, destroy as deleteEvent } from "../../../actions/event";
import { send as pageInfo } from "../../../actions/page";
import { list as getCourts } from "../../../actions/court";

import Representation from "./representation";
import Payments from "./payments";
import Trusts from "./trusts";
import Matters from "./matters";
import Matter from "./matter";
import Timecards from "./timecards";
import Expenses from "./expenses";
import Personal from "./person";
import Company from "./company";
import Invoices from "./invoices";
import Calendar from "./calendar";
import Files from "./files";

import Timecard from "../../../layout/timecard";
import Payment from "../../../layout/payment";
import Expense from "../../../layout/expense";
import Invoice from "../../../layout/invoice";
import Event from "../../../layout/event";
import Summary from "./summary";

class Client extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "profile",
            representationStarted: null,
            representationEnded: null,
            attorneyUuid: null,
            invoice: {
                updated: Date.now()
            },
            Payments: [],
            Trusts: [],
            Matters: [],
            Tabs: [],
            Codes: {
                attorneys: [],
                parties: [],
                results: [],
                types: [],
                payments: [],
                integrations: []
            },
            uuid: this.props.match.params.uuid || uuidv4(),
            modal: {
                timecard: {
                    show: false,
                },
                payment: {
                    show: false,
                },
                expense: {
                    show: false,
                },
                invoice: {
                    show: false,
                },
                event: {
                    show: false,
                }
            }
        };
    }

    onTimecardOk = async (e) => {
        this.onTimecardHideModal(e);
        e.clientUuid = isGuid(e.clientUuid) ? e.clientUuid : this.state.uuid;
        await this.props.saveClock(e);
    }

    onPaymentOk = async (e) => {
        this.onPaymentHideModal(e);
        e.clientUuid = isGuid(e.clientUuid) ? e.clientUuid : this.state.uuid;
        await this.props.savePayment(e);
    }

    onExpenseOk = async (e) => {
        this.onExpenseHideModal(e);
        e.clientUuid = isGuid(e.clientUuid) ? e.clientUuid : this.state.uuid;
        await this.props.saveExpense(e);
    }

    onEventSelect = async (e) => {
        const __event = await this.props.getClientEvents(this.state.uuid, null, null, e);
        if (__event && __event.length) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        selected: __event[0],
                        shares: __event[0].SubjectActivityShares,
                        show: true
                    }
                }
            });
        }
    }

    onInvoiceOk = async (e) => {
        this.onInvoiceHideModal(e);
    }

    onEventOk = async (e) => {
        this.onEventHideModal(e);
        e.subjectUuid = this.state.uuid;
        e.path = "clients";
        const event = await this.props.saveEvent(e);

        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CONFIRMED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    onEventDelete = async (e,) => {
        this.onEventHideModal(e);
        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            const event = this.props.getClientEvents(this.state.uuid, null, null, e.uuid);

            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CANCELLED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        await this.props.deleteEvent({ uuid: e.uuid, subjectUuid: this.state.uuid, path: "clients" });

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    sendEventMail = async (toEmail, event, icsString) => {
        setTimeout(async () => {            
            const __fileName = `invite_${event.uuid}.ics`;
            const _xml = JavaScripttoXML(event); 
            const built = await build(_xml, "EVENT.SEND");
            await send(built.from, toEmail, built.subject, built.html, [{ filename: __fileName, encoding: 'utf8', content: icsString }]);
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        updated: Date.now()
                    }
                }
            })
        }, 30);
    }
 
    onTimecardHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                timecard: {
                    ...this.state.modal.timecard,
                    show: false
                }
            }
        })
    }

    onPaymentHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                payment: {
                    ...this.state.modal.payment,
                    show: false
                }
            }
        })
    }

    onExpenseHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                expense: {
                    ...this.state.modal.expense,
                    show: false
                }
            }
        })
    }

    onInvoiceHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                invoice: {
                    ...this.state.modal.invoice,
                    show: false
                }
            }
        })
    }

    onEventHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    show: false
                }
            }
        })
    }

    componentDidMount = () => {
        setTimeout(() => {
            if (isGuid(this.uuid)) { this.loadData(); }
        }, 30);
    }

    get uuid() {
        return this.props.match.params.uuid;
    }

    get shareables() {
        return [
            {
                uuid: this.state.uuid,
                firstName: this.state.Person ? this.state.Person.firstName : this.state.Company?.name || "",
                lastName: this.state.Person ? this.state.Person.lastName : ""
            }
        ];
    }

    loadData = async () => {
        await this.props.getReports({ category: "Client" });
        const attorneys = await this.props.getAttorneys();
        const data = await this.props.getClient(this.uuid);

        const types = await this.props.getCodes("MT");
        const parties = await this.props.getCodes("PA");
        const results = await this.props.getCodes("RC");
        const payments = await this.props.getCodes("PS");
        const integrations = await this.props.getIntegrations();
        const activities = await this.props.getActivities("Client");
        const courts = await this.props.getCourts();

        const { uuid: personUuid, firstName, middleName, lastName, generation, email, Phone } = data?.Person ? data.Person : { uuid: data.uuid, firstName: "", middleName: "", lastName: "", generation: "", email: "", Phone: {} };
        const { uuid: companyUuid, name, dba, Phone: companyPhone } = data?.Company ? data.Company : { uuid: data.uuid, name: "", dba: "", Phone: {} };
        const { uuid, representationStarted, representationEnded, attorneyUuid, Payments, Trust, Matters } = data;
        const PersonAddress = data?.Person?.PersonAddresses?.length > 0 ? data?.Person?.PersonAddresses[0]?.Address : { uuid: data.uuid, line1: "", line2: "", city: "", state: "", county: "", country: "US", zipCode: "" };
        const CompanyAddress = data?.Company?.CompanyAddresses?.length > 0 ? data?.Company?.CompanyAddresses[0]?.Address : { uuid: data.uuid, line1: "", line2: "", city: "", state: "", county: "", country: "US", zipCode: "" };
        const logs = await this.props.getLogs(this.uuid);
        await this.props.pageInfo({breadcrumb: `This Client (${firstName ? firstName + " " : ""}${lastName ? lastName : ""}${name ? name : ""})`})
        const __state_data = {
            representationStarted, representationEnded, attorneyUuid,
            uuid,
            logs,
            Payments,
            Trusts: Trust,
            Matters,
            detail: data,
            Codes: {
                types,
                parties,
                results,
                attorneys,
                payments,
                integrations,
                activities,
                courts
            }
        };
        if (data?.Person) {
            __state_data.Person = { uuid: personUuid, firstName, middleName, lastName, generation, email, Phone, Address: PersonAddress };
        }
        if (data?.Company) {
            __state_data.Company = { uuid: companyUuid, name, dba, Phone: companyPhone, Address: CompanyAddress };
        }

        
        this.setState(__state_data);
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    onPersonChange = (value, errors) => {
        this.setState({
            ...this.state,
            Person: {
                ...value
            },
            errors: errors
        });
    }

    onCompanyChange = (value, errors) => {
        this.setState({
            ...this.state,
            Company: {
                ...value
            },
            errors: errors
        });
    }

    onClientChange = (value) => {
        this.setState({
            ...this.state,
            ...value
        });
    }

    onSaveClick = async (e) => {
        const __data = {
            uuid: this.state.uuid,
            representationStarted: this.state.representationStarted,
            representationEnded: this.state.representationEnded,
            attorneyUuid: this.state.attorneyUuid,
            Matters: this.state.Matters
        };
        if (this.state?.Person) __data.Person = this.state.Person;
        if (this.state?.Company) __data.Company = this.state.Company;

        const result = await this.props.saveClient(__data);

        if (result.success) {
            const __result = {};
            if (result.person) __result.Person = result.person;
            if (result.company) __result.Company = result.company;
            this.setState({
                ...this.state,
                ...__result
            })
        }
    }

    onTerminateClick = async (e) => {
        const result = await this.props.saveClient
            (
                {
                    uuid: this.state.uuid,
                    representationEnded: Date.now()
                }
            );
        
        if (result.success) {
            this.setState({
                ...this.state,
                representationEnded: Date.now()
            })
        }
    }

    onReactivateClick = async (e) => {
        const result = await this.props.saveClient
            (
                {
                    uuid: this.state.uuid,
                    representationEnded: null
                }
            );
        
        if (result.success) {
            this.setState({
                ...this.state,
                representationEnded: null
            })
        }
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    onTabChange = (e) => {
        this.setState({
            ...this.state,
            selected: e
        });
    }

    onLogSave = async (e) => {
        e.targetUuid = this.state.uuid;
        e.sourceCode = "CL";
        e.addDate = Date.now();
        await this.props.saveLog(e);
        const __logs = this.state.logs;
        __logs.push({
            ...e,
            Person: {
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName
            }
        });

        this.setState({
            ...this.state,
            logs: __logs
        })
    }

    onMatterSelect = async (e) => {
        const __tabs = this.state.Tabs;
        const __tab = __tabs.find(obj => {
            return obj.uuid === e;
        });
        if (!__tab) {
            const __matter = this.state.Matters.find(obj => {
                return obj.uuid === e;
            });

            __tabs.push({
                ...__matter,
                subject: "Matter"
            });

            this.setState({
                ...this.state,
                Tabs: __tabs,
                selected: __matter.uuid
            })
        }
    }

    onTimecardSelect = async (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                timecard: {
                    ...this.state.modal.timecard,
                    ...e,
                    show: true
                }
            }
        })
    }

    onMatterChange = async (e) => {
        const __matters = this.state.Matters;
        const __tabs = this.state.Tabs;
        
        for (let i = 0; i < __matters.length; i++) {
            const item = __matters[i];
            if (item.uuid === e.uuid) {
                __matters[i] = {
                    ...item,
                    ...e
                }
            }
        }

        for (let i = 0; i < __tabs.length; i++) {
            const __tab = __tabs[i];
            if ((__tab.uuid === e.uuid) && (__tab.subject === "Matter")) {
                __tabs[i] = {
                    ...__tab,
                    ...e
                }
            }
        }
        
        this.setState({
            ...this.state,
            Matters: __matters,
            Tabs: __tabs
        })
    }

    onRepresentationChange = async (e) => {
        const { representationStarted, representationEnded, attorneyUuid } = e;

        this.setState({
            ...this.state,
            representationStarted,
            representationEnded,
            attorneyUuid
        })
    }

    onNewMatter = (e) => {
        const __matters = this.state.Matters;
        const __uuid = uuidv4();

        __matters.push({
            uuid: __uuid,
            clientUuid: this.state.uuid,
            description: "New Matter",
            typeCode: "OT",
            partyCode: "O",
            resultCode: "IP",
            interval: 6,
            representationStarted: Date.now(),
            courts: this.state.courts
        });

        this.setState({
            ...this.state,
            Matters: __matters
        });

        setTimeout(() => {
            this.onMatterSelect(__uuid);
        }, 30);
    }

    onRecordTime = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                timecard: {
                    ...this.state.modal.timecard,
                    show: true
                }
            }
        })
    }

    onRecordPayment = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                payment: {
                    ...this.state.modal.payment,
                    show: true
                }
            }
        })
    }

    onRecordExpense = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                expense: {
                    ...this.state.modal.expense,
                    show: true
                }
            }
        })
    }

    onRecordEvent = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    selected: {
                        startDate: e.startDate,
                        endDate: e.endDate
                    },
                    show: true
                }
            }
        })
    }

    onRecordInvoice = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                invoice: {
                    ...this.state.modal.invoice,
                    show: true
                }
            }
        })
    }

    onReportClick = async (e, data) => {
        const _leads_object = {
            sourceCategory: this.state.sourceCategory,
            retainer: this.state.retainer,
            partner: this.state.partner,
            associate: this.state.associate,
            subject: this.state.subject,
            uuid: this.state.uuid,
        }
        if (this.state.Person) _leads_object.Person = { ...this.state.Person };

        const _xml = JavaScripttoXML(_leads_object);
        if (data.outputCode === "RTF") {
            const _result = await this.props.generateDocx(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else if (data.outputCode === "PDF") {
            const _result = await this.props.generatePdf(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    onInvoiceDownload = async (invoice) => {
        setTimeout(async () => {
            const __invoice = await this.props.getInvoice(invoice);
            if (this.state.Person) __invoice.Person = this.state.Person;
            if (this.state.Company) __invoice.Company = this.state.Company;
            
            const __fileName = `${__invoice.Company?.name?.length ? __invoice.Company?.name : __invoice.Person.lastName + ", " + __invoice.Person.firstName} ${formatDate(__invoice.invoiceGenerated)} Invoice ${__invoice.number}.pdf`;
            const _xml = JavaScripttoXML(__invoice);
            const _result = await this.props.generateXsl(_xml, "270f0523-4e61-11ed-a3e9-0efa9373c42b");

            const linkSource = `data:application/pdf;base64,${_result}`;
            const link = document.createElement("a");

            link.href = linkSource;
            link.setAttribute(
                'download',
                __fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }, 30);
    }

    onInvoiceSend = async (invoice) => {
        setTimeout(async () => {
            const __invoice = await this.props.getInvoice(invoice);
            if (this.state.Person) __invoice.Person = this.state.Person;
            if (this.state.Company) __invoice.Company = this.state.Company;
            
            const __fileName = `${__invoice.Company?.name?.length ? __invoice.Company?.name : __invoice.Person.lastName + ", " + __invoice.Person.firstName} ${formatDate(__invoice.invoiceGenerated)} Invoice ${__invoice.number}.pdf`;
            const _xml = JavaScripttoXML(__invoice);
            const _result = await this.props.generateXsl(_xml, "270f0523-4e61-11ed-a3e9-0efa9373c42b");
            const built = await build(_xml, "INVOICE.SEND");
            await send(built.from, this.state.Person.email, built.subject, built.html, [{ filename: __fileName, encoding: 'base64', content: _result }]);
            const updated = await this.props.putInvoice(this.uuid, { invoiceSent: Date.now(), uuid: __invoice.uuid });
            this.setState({
                ...this.state,
                invoice: {
                    ...this.state.invoice,
                    updated: Date.now()
                }
            })
        }, 30);
    }

    onInvoicePublish = async (invoice, hide) => {
        setTimeout(async () => {
            const updated = await this.props.putInvoice(this.uuid, { invoicePublished: (hide ? null : Date.now()), uuid: invoice });
            this.setState({
                ...this.state,
                invoice: {
                    ...this.state.invoice,
                    updated: Date.now()
                }
            })
        }, 30);
    }


    onExport = async (e, data) => {
        const result = await this.props.doIntegrate(data.uuid, this.state.uuid);
        if (result.success) {
            this.props.setAlerts(["Client data sent"], "success");
        } else {
            this.props.setAlerts(["Error during integration"], "error");
        }
    }

    get integrations() {
        const result = [];
        this.state.Codes.integrations.forEach(element => {
            result.push({
                onClick: this.onExport,
                caption: `To ${element.caption}`,
                uuid: element.uuid
            });
        });
        return result;
    }

    get hasIntegrations() {
        return this.integrations.length > 0;
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Record New Time" enabled display onEnabledClick={this.onRecordTime} icon="clock">Record Time</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Terminate this client" display={!this.state.representationEnded} enabled={true} onEnabledClick={this.onTerminateClick} icon="save">Terminate</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Activate this previous client" display={this.state.representationEnded} enabled={true} onEnabledClick={this.onReactivateClick} icon="save">Re-Activate</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Save my information" display={true} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                    <ConditionalDropdown className="ms-sm-1" enabledVariant="secondary" enabled display={this.hasIntegrations} items={this.integrations}>Export</ConditionalDropdown>
                </Col>
                <Tab.Container activeKey={this.state.selected}>
                    <Card.Header className="border-0 pb-0">
                        <Nav className="nav-tabs-line card-header-tabs" variant="tabs" activeKey={this.state.selected}>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#profile"
                                    className={`${this.state.selected === 'profile' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('profile');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Profile
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#calendar"
                                    className={`${this.state.selected === 'calendar' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('calendar');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Calendar
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#files"
                                    className={`${this.state.selected === 'files' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('files');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Files
                                </Nav.Link>
                            </Nav.Item>
                            {this.state.Tabs.map((item, index) => {
                                return <Nav.Item className="text-center" key={`matter.tab.${item.uuid}.${index}`}>
                                    <Nav.Link
                                        href={`#${item.uuid}`}
                                        className={`${this.state.selected === item.uuid && 'active'}`}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            this.onTabChange(item.uuid);
                                        }}
                                        style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                    >
                                        {`${item.subject}: ${item.description}`}
                                    </Nav.Link>
                                </Nav.Item>
                            })}
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#notes"
                                    className={`${this.state.selected === 'notes' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('notes');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Col md="12">
                        <Form name="frm" action="post" onSubmit={this.onSubmit} >
                            <Tab.Content className=" h-100">
                                <Tab.Pane active={this.state.selected === 'profile'} className="h-100 scroll-out">
                                    <Card body>
                                        <Row>
                                            <Col md="3" sm="12">
                                                <Card body className="mb-3">
                                                    <Representation representationEnded={this.state.representationEnded}
                                                        representationStarted={this.state.representationStarted}
                                                        attorneyUuid={this.state.attorneyUuid}
                                                        attorneys={this.state.Codes.attorneys}
                                                        onChange={this.onRepresentationChange}
                                                    />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Summary client={this.state.uuid} />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Payments client={this.state.uuid} onCreate={this.onRecordPayment} />
                                                </Card>
                                                <Card body>
                                                    <Trusts client={this.state.uuid} />
                                                </Card>
                                            </Col>
                                            <Col md="9" sm="12">
                                                <Card body className="mb-3">
                                                    <Personal {...this.state.Person} onChange={this.onPersonChange} />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Company {...this.state.Company} onChange={this.onCompanyChange} />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Matters
                                                        {...this.state.Codes}
                                                        items={this.state.Matters}
                                                        onSelect={this.onMatterSelect}
                                                        onCreate={this.onNewMatter}
                                                    />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Invoices
                                                        client={this.state.uuid}
                                                        onSelect={this.onInvoiceSelect}
                                                        onPublish={this.onInvoicePublish}
                                                        onDownload={this.onInvoiceDownload}
                                                        onSend={this.onInvoiceSend}
                                                        onCreate={this.onRecordInvoice}
                                                    />
                                                </Card>
                                                <Card body className="mb-3">
                                                    <Timecards
                                                        matters={this.state.Matters}
                                                        onSelect={this.onTimecardSelect}
                                                        onCreate={this.onRecordTime}
                                                        uuid={this.state.uuid}
                                                    />
                                                </Card>
                                                <Card body>
                                                    <Expenses
                                                        client={this.state.uuid}
                                                        onSelect={this.onExpenseSelect}
                                                        onCreate={this.onRecordExpense}
                                                        uuid={this.state.uuid}
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane active={this.state.selected === 'calendar'} className="h-100 scroll-out">
                                    {this.state.selected === 'calendar' ? (
                                        <Card body className="mb-5">
                                            <Calendar client={this.state.uuid} onSelect={ this.onEventSelect } updated={this.state.event?.updated} onCreate={this.onRecordEvent}></Calendar>
                                        </Card>
                                    ) : ``}
                                </Tab.Pane>
                                <Tab.Pane active={this.state.selected === 'files'} className="h-100 scroll-out">
                                    {this.state.selected === 'files' ? (
                                        <Card body className="mb-5">
                                            <Files
                                                client={this.state.uuid}
                                                onSelect={this.onFileSelect}
                                                updated={this.state.event?.updated}
                                                shareable={this.shareables}
                                            />
                                        </Card>
                                    ) : ``}
                                </Tab.Pane>
                                {this.state.Tabs.map((element, index) => {
                                    return <Tab.Pane active={this.state.selected === element.uuid} className="h-100 scroll-out" key={`matter.pane.${element.uuid}.${index}`}>
                                        <Card body className="mb-5">
                                            <Matter {...element} Codes={this.state.Codes} onChange={this.onMatterChange} />
                                        </Card>
                                    </Tab.Pane>
                                })}
                                <Tab.Pane active={this.state.selected === 'notes'} className="h-100 scroll-out">
                                    <Card body className="mb-5">
                                        <Logs logs={this.state.logs} onSave={this.onLogSave} />
                                    </Card>
                                </Tab.Pane>

                            </Tab.Content>
                        </Form>
                    </Col>
                </Tab.Container>
                <Timecard
                    show={this.state.modal.timecard.show}
                    clock={{
                        started: this.state.modal.timecard.clockStarted,
                        ended: this.state.modal.timecard.clockEnded,
                        minutes: this.state.modal.timecard.minutes
                    }}
                    notes={this.state.modal.timecard.notes}
                    uuids={{
                        matter: this.state.modal.timecard.matterUuid,
                        employee: this.state.modal.timecard.employeeUuid
                    }}
                    uuid={this.state.modal.timecard.uuid}
                    onCancel={this.onTimecardHideModal}
                    onOk={this.onTimecardOk}
                    matters={this.state.Matters} />
                <Payment
                    show={this.state.modal.payment.show}
                    source={this.state.modal.payment.source}
                    amount={this.state.modal.payment.amount}
                    reference={this.state.modal.payment.reference}
                    uuid={this.state.modal.payment.uuid}
                    recorder={this.state.modal.payment.recorder}
                    sources={this.state.Codes.payments}
                    onCancel={this.onPaymentHideModal}
                    onOk={this.onPaymentOk}
                />
                <Expense
                    show={this.state.modal.expense.show}
                    date={this.state.modal.expense.expenseDate}
                    merchant={this.state.modal.expense.merchant}
                    charge={this.state.modal.expense.charge}
                    notes={this.state.modal.expense.notes}
                    category={this.state.modal.expense.category}
                    uuids={{
                        matter: this.state.modal.expense.matterUuid,
                        employee: this.state.modal.expense.employeeUuid
                    }}
                    uuid={this.state.modal.expense.uuid}
                    onCancel={this.onExpenseHideModal}
                    onOk={this.onExpenseOk}
                    matters={this.state.Matters}
                />
                <Invoice
                    show={this.state.modal.invoice.show}
                    onCancel={this.onInvoiceHideModal}
                    onOk={this.onInvoiceOk}
                    client={this.uuid}
                    updated={this.state.invoice.updated}
                />
                <Event
                    show={this.state.modal.event.show}
                    onDelete={this.onEventDelete}
                    onCancel={this.onEventHideModal}
                    onOk={this.onEventOk}
                    activities={this.state.Codes.activities}
                    employees={this.state.Codes.attorneys}
                    shareable={
                        [
                            {
                                uuid: this.state.uuid,
                                firstName: this.state.Person ? this.state.Person.firstName : this.state.Company?.name || "",
                                lastName: this.state.Person ? this.state.Person.lastName : ""
                            }
                        ]
                    }
                    shares={this.state.modal.event.shares}
                    {...this.state.modal.event.selected}
                />
            </>
        )
    }
}

Client.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    reports: PropTypes.array,
    fields: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        reports: state.licensee.reports,
        fields: state.fields.items
    });
};

export default connect(mapStateToProps, { pageInfo, doIntegrate, getCourts, getClient, getClientEvents, getIntegrations, getInvoice, getCodes, getActivities,  deleteEvent, saveEvent, saveClient, saveClock, savePayment, saveExpense, getLogs, saveLog, getAttorneys, setAlerts, getReports, getReport, generateDocx, generatePdf, generateXsl, named, putInvoice })(Client);
