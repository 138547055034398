import api from "../utils/api";

import { LOGOUT } from "./types";

export const add = ({ matterUuid, personUuid, partyType, uuid, prose, save, barNumber, firstName, middleName, lastName, email, Phone, name, Address }) => async (dispatch) => {
    try {
        const res = await api.post("/files/unzip", { uuid });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}