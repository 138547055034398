
import React from "react";
import { DropdownButton, ButtonGroup } from 'react-bootstrap';

import Item from "./item";

class ConditionalDropdown extends React.Component {

    render() {
        if (this.props.display)
        {
            return (
                <DropdownButton drop="down" as={ButtonGroup} className={`${this.props.className}`} title={this.props.children} variant="primary">
                    {this.props.items.map((item, index) => {
                        return (
                            <Item key={`dditem.${index}`} {...item} index={index} />
                        );
                    })}
                </DropdownButton>
            )
        }
        return null;
    }
}

export default ConditionalDropdown;
