import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import AttorneySelect, { exists as AttorneyExists } from "../../../layout/select/attorney-list";
import { deepEqual } from "../../../utils/object";
import { format as formatDate } from "../../../utils/date";
import { isGuid } from "../../../utils/string";

import 'react-intl-tel-input/dist/main.css';
import 'react-datepicker/dist/react-datepicker.css';

class Personal extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            attorneyUuid: "",
            representationStarted: null,
            representationEnded: null
        };
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                });
            } 
            if (this.props.fields.length !== oldProps.fields.length)
                this.postChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.postChange)
    }

    postChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, []);
        }
    }

    onAttorneyChange = (e) => {
        if (isGuid(e?.value)) {
            this.setState({ ...this.state, attorneyUuid: e?.value }, this.postChange);
        }
    }


    render() {
        return (
            <Row>
                <Col lg="12"><p>Representation started {formatDate(this.props.representationStarted)}{
                    this.props.representationEnded ?
                        ` ended ${formatDate(this.props.representationEnded)}` :
                        ``
                }
                    <span className={`badge rounded-pill bg-${this.props.representationEnded ? "danger" : "success"}`} style={{ marginLeft: "5px" }}>{this.props.representationEnded ? "PAST" : "CURRENT"}</span></p>
                </Col>
                <Col lg="12">
                    <div className="mb-3 top-label">
                        <AttorneySelect attorneys={ this.props.attorneys } value={this.state.attorneyUuid || ""} onChange={this.onAttorneyChange} placeholder="" name="attorneyUuid" />
                        <Form.Label>ATTORNEY IN CHARGE</Form.Label>
                    </div>
                </Col>
            </Row>
        )
    }
}

Personal.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Personal);

