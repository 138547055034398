import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Table from "../../../../layout/table";
import ConditionalButton from "../../../../layout/conditional-button";
import Party from "../../../../layout/party";
import Modal from "../../../../layout/modal";

import { phone, isGuid } from "../../../../utils/string";
import { only as numbersOnly } from "../../../../utils/number";
import { deepEqual } from "../../../../utils/object";
import { list as getCodes } from "../../../../actions/code";
import { parties as getParties } from "../../../../actions/matter";
import { add as saveParty } from "../../../../actions/party";

class Parties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "lastName",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            sources: [],
            modal: {
                party: {
                    show: false
                },
                destroy: {
                    show: false
                }
            }
        };
        
    }

    componentDidUpdate = (old) => {
        if (
            (!deepEqual(old?.matter, this.props?.matter)) ||
            (!deepEqual(old?.updated, this.props?.updated)) 
        ) {
            this.fetchData();
        } 
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }


    fetchData = async () => {
        setTimeout( async () => {

            const __data = await this.props.getCodes("PT");
            let __count = 0;

            if (this.props.parties?.length || 0) {
                this.setState({
                    ...this.state,
                    types: __data,
                    data: this.getFlattenedArray()
                })
            } else {
                const __parties = await this.props.getParties(this.props.matter, this.state.page, this.state.size, this.state.order, this.state.direction);
                if ((__parties) && (__parties.length || 0 > 0))
                    __count = Math.ceil(Number(__parties[0].count) / Number(this.state.size));

                this.setState({
                    ...this.state,
                    types: __data,
                    data: __parties,
                    pages: __count
                });
            }
        }, 30);
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onNameLinkFormat
            },
            {
                header: "Firm",
                sortable: true,
                source: "Person.Company.name",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onLinkFormat
            },
            {
                header: "Phone",
                sortable: true,
                source: "Phone.number",
                className: "text-muted text-small text-uppercase w-45",
                onRender: this.onPhoneLinkFormat
            },
            {
                header: "Email",
                sortable: true,
                source: "Person.email",
                className: "text-muted text-small text-uppercase w-25",
                onRender: this.onLinkFormat
            }
        ]
    }

    getFlattened(item) {
        const __result = {
            "matterUuid": item.matterUuid,
            "personUuid": item.personUuid,
            "partyType": item.partyType,
            "uuid": item.uuid,
            "Person.uuid": item.Person.uuid,
            "Person.firstName": item.Person.firstName,
            "Person.lastName": item.Person.lastName,
            "Person.middleName": item.Person.middleName,
            "Person.email": item.Person.email,
            "Person.barNumber": item.Person.barNumber,
            "Person.companyUuid": item.Person.companyUuid,
            "Person.phoneUuid": item.Person.phoneUuid,
            "Person.prose": item.Person.prose,
            "Person.save": item.Person.save,
            "Person.Phone.number": item.Person.Phone.number,
            "Person.Phone.country": item.Person.Phone.country,
            "Person.Phone.sms": item.Person.Phone.sms,
            "Person.Phone.uuid": item.Person.Phone.uuid,
            "Person.Address.uuid": item.Person.Address.uuid,
            "Person.Address.line1": item.Person.Address.line1,
            "Person.Address.line2": item.Person.Address.line2,
            "Person.Address.city": item.Person.Address.city,
            "Person.Address.county": item.Person.Address.county,
            "Person.Address.state": item.Person.Address.state,
            "Person.Address.zipCode": item.Person.Address.zipCode,
            "Person.Address.country": item.Person.Address.country,
            "Person.Company.uuid": item.Person.Company.uuid,
            "Person.Company.name": item.Person.Company.name
        }
        return __result;
    }

    getFlattenedArray() {
        const __array = this.props.parties;
        const result = [];
        for (let i = 0; i < __array?.length; i++) result.push(this.getFlattened(__array[i]));
        return result;
    }

    getStructured(item) {
        const __result = {
            matterUuid: this.props.matter,
            personUuid: item["Person.uuid"],
            partyType: item["partyType"],
            uuid: item["uuid"],
            Person: {
                uuid: item["Person.uuid"],
                firstName: item["Person.firstName"],
                lastName: item["Person.lastName"],
                middleName: item["Person.middleName"],
                email: item["Person.email"],
                barNumber: item["Person.barNumber"],
                companyUuid: item["Person.companyUuid"],
                phoneUuid: item["Person.phoneUuid"],
                prose: item["Person.prose"],
                save: item["Person.save"],
                Phone: {
                    number: item["Person.Phone.number"],
                    country: item["Person.Phone.country"],
                    sms: item["Person.Phone.sms"],
                    uuid: item["Person.Phone.uuid"],
                },
                Address: {
                    uuid: item["Person.Address.uuid"],
                    line1: item["Person.Address.line1"],
                    line2: item["Person.Address.line2"],
                    city: item["Person.Address.city"],
                    county: item["Person.Address.county"],
                    state: item["Person.Address.state"],
                    zipCode: item["Person.Address.zipCode"],
                    country: item["Person.Address.country"]
                },
                Company: {
                    uuid: item["Person.Company.uuid"],
                    name: item["Person.Company.name"],
                }
            }
        };

        return __result;
    }

    getStructuredArray() {
        const __array = this.state.data;
        const result = [];
        for (let i = 0; i < __array.length; i++) result.push(this.getStructured(__array[i]));
        return result;
    }

    onItemClick = (e) => {
        const uuid = e.target.attributes["uuid"].value;
        const __array = this.state.data;
        const __idx = __array.findIndex(option => option.uuid === uuid);
        if (__idx >= 0) {
            const __item = __array[__idx];
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    party: {
                        show: true,
                        selected: this.getStructured(__item)
                    }
                }
            })
        }
    }


    onNameLinkFormat = (e, row) => {
        const __name = this.onLinkFormat(
            `${row["Person.lastName"]}, ${row["Person.firstName"]}`,
            row
        );
        const __types = this.state.types;
        const __type = __types.find(option => option.value === row["partyType"]);
        
        return (
            <>
                {__name}
                <span
                    role="button"
                    className="badge rounded-pill bg-info"
                    style={
                        {
                            marginLeft: "5px",
                            paddingLeft: "15px",
                            paddingRight: "15px"
                        }}>{__type.description.toLowerCase() }</span>
                {(row["Person.save"]) ? (
                    <span
                        role="button"
                        className="badge rounded-pill bg-info"
                        style={
                            {
                                marginLeft: "5px",
                                paddingLeft: "15px",
                                paddingRight: "15px"
                            }}>saved</span>
                ) : (``)}
            </>
        )
    }

    onPhoneLinkFormat = (e, row) => {
        let __phone = numbersOnly(row["Person.Phone.number"]);
        __phone = (__phone?.length === 10) ? ("1" + __phone) : __phone; 
        return <a href={`tel:+${__phone}`}>{phone(__phone) ? phone(__phone) : __phone}</a>
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid?.toString()}
            matter={row.matterUuid?.toString()}
            onClick={this.onItemClick}
            href="#"
            role="link">{e}</a>
    }

    getSource = (value) => {
        let __result = this.state.sources.find(obj => {
            return obj.value === value;
        });
        return (__result ? __result.description : "");
    }

    postChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(this.getStructuredArray(this.state.data))
        };
    }

    onPartyOk = async (e) => {
        e.uuid = isGuid(e.uuid) ? e.uuid : uuidv4();
        e.matterUuid = this.props.matter;
        e.personUuid = isGuid(e.personUuid) ? e.personUuid : e.uuid;
        
        const __array = this.state.data;
        const __mapped = this.getFlattened(e); 

        const __index =  __array.findIndex((item) => {
            return (item.uuid === e.uuid);
        });

        if (__index < 0) {
            __array.push(__mapped);
        } else {
            __array[__index] = { ...__array[__index], ...__mapped };
        }
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                party: {
                    ...this.state.modal.party,
                    show: false
                }
            },
            data: [...__array]
        }, this.postChange);
    }

    onPartyHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                party: {
                    ...this.state.modal.party,
                    show: false
                }
            }
        })
    }

    onDestroyOk = (e) => {
        const __array = this.state.data;
        const __index = __array.findIndex((item) => {
            return (item.uuid === this.state.modal.destroy.data.uuid);
        });

        if (__index >= 0) {
            __array.splice(__index, 1);
            this.setState({
                ...this.state,
                data: __array,
                modal: {
                    ...this.state.modal,
                    party: {
                        ...this.state.modal.party,
                        show: false
                    },
                    destroy: {
                        ...this.state.modal.destroy,
                        show: false
                    }
                }
            }, this.postChange);
        }
    }

    onDestroyHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    ...this.state.modal.destroy,
                    show: false
                }
            }
        })
    }

    doShow = () => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                party: {
                    ...this.state.modal.party,
                    show: true
                }
            }
        })
    }

    onCreate = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                party: {
                    ...this.state.modal.party,
                    selected: null,
                }
            }
        }, this.doShow)
    }

    onDelete = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    show: true,
                    title: 'Confirm destruction',
                    body:
                        `Confirm destruction of this party. This will permanently remove this party from this matter. If this party is not used anywhere `+
                        `else, this information will be purged from the system. This action cannot be undone. Click ok to continue or cancel to return to editor.`,
                    data: { ...e }
                }
            }
        })
    }


    render() {
        return (
            <>
                <Col xs="12">
                    <Row>
                        <Col xs="6">
                            <h2 className="small-title">Parties</h2>
                        </Col>
                        <Col xs="6" style={{ textAlign: "right" }}>
                            <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCreate} icon="plus">Create Party</ConditionalButton>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>

                <Party
                    show={this.state.modal.party.show}
                    selected={this.state.modal.party.selected}
                    types={ this.state.types }
                    onCancel={this.onPartyHideModal}
                    onOk={this.onPartyOk}
                    onDelete={this.onDelete}
                />

                <Modal show={this.state.modal.destroy.show} title={ this.state.modal.destroy.title } body={this.state.modal.destroy.body} buttons={[{
                        default: true,
                        caption: "cancel",
                        onClick: this.onDestroyHideModal,
                        variant: "secondary"
                    },
                    {
                        caption: "ok",
                        onClick: this.onDestroyOk,
                        variant: "outline-primary"
                    }]} /> 
            </>
        )
    }
}

Parties.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updated: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        updated: state.client.expense
    });
};

export default connect(mapStateToProps, { getCodes, getParties, saveParty })(Parties);