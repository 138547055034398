import { combineReducers } from "redux";
import auth from "./auth";
import licensee from "./licensee";
import notification from "./notification";
import alert from "./alert";
import fields from "./field";
import person from "./person";
import client from "./client";
import page from "./page";

export default combineReducers({
  auth,
  alert,
  licensee,
  notification,
  fields,
  person,
  client,
  page
});
