import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";

import { format as formatDate } from "../../../utils/date";
import { deepEqual } from "../../../utils/object";
import { list as getCodes } from "../../../actions/code";
import { trusts as getTrusts } from "../../../actions/client";
import { currency as formatCurrency } from "../../../utils/number";

class Trusts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "dateOf",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.client, this.props?.client)) {
            this.fetchData();
        } else if (!deepEqual(old?.updated, this.props?.updated)) {
            this.fetchData();
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }

    fetchData = async () => {
        setTimeout( async () => {

            const __data = await this.props.getCodes("TT");
            const __trusts = await this.props.getTrusts(this.props.client, this.state.page, this.state.size, this.state.order, this.state.direction);
            let __count = 0;

            if ((__trusts) && (__trusts.length || 0 > 0))
                __count = Math.ceil(Number(__trusts[0].count) / Number(this.state.size));

            this.setState({
                ...this.state,
                types: __data,
                data: __trusts,
                pages: __count
            });
        }, 30);
    }

    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "dateOf",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onDateLinkFormat
            },
            {
                header: "Description",
                sortable: true,
                source: "description",
                className: "text-muted text-small text-uppercase w-25",
                onRender: this.onDescriptionLinkFormat
            },
            {
                header: "Amount",
                sortable: true,
                source: "amount",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect({
            uuid: e.target.attributes["uuid"].value,
            trustUuid: e.target.attributes["trust"].value,
            typeCode: e.target.attributes["typecode"].value,
            description: e.target.attributes["description"].value,
            amount: e.target.attributes["amount"].value,
            dateOf: e.target.attributes["date"].value
        });
    }

    getType = (value) => {
        let __result = this.state.types.find(obj => {
            return obj.value === value;
        });
        return (__result ? __result.description : "");
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number(e));
    }

    onDateFormat = (e) => {
        return formatDate(e, true);
    }

    onDateLinkFormat = (e, row) => {
        return this.onLinkFormat(formatDate(e), row);
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()}
            trust={row.trustUuid.toString()}
            typecode={row.typeCode.toString()}
            description={row.description.toString()}
            amount={row.amount.toString()}
            date={row.dateOf.toString()}
            onClick={this.onItemClick}
            href="#"
            role="link">{e}</a>
    }

    onDescriptionLinkFormat = (e, row) => {
        return this.onLinkFormat(`${e}`, row); //(${this.getType(row['typeCode'])})
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    render() {
        return (
            <>
                <Col xs="12">
                    <h2 className="small-title">Trusts</h2>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Trusts.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updated: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        updated: state.client.trust
    });
};

export default connect(mapStateToProps, { getCodes, getTrusts })(Trusts);