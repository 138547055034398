import React from "react";
import { NavLink } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import pages from "../../../constants/menu";
import MenuItems from "./items"; 
import "./style.css";

class Mobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: false
    }
  }
  doToggle = (e) => {
    this.setState({
      showing: !Boolean(this.state.showing)
    })
  }

  render() {
    if (!this.props.auth) return;
    return (
      <>
        <nav className="topnav">
          <div className="mobileNavButton" onClick={this.doToggle}>
            <span className="mobileNavButtonLines" />
            <span className="mobileNavButtonLines" />
            <span className="mobileNavButtonLines" />
          </div>
          <ul className="mobileMenuLinks" id="mobileMenuLinks" style={{display: this.state.showing ? "block": "none"}}>
            <MenuItems items={pages[0].contains.items} />
          </ul>
        </nav>
      </>
    )
  }
}

Mobile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.licensee.profile,
  };
};

export default connect(mapStateToProps)(Mobile);
