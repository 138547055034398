import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import { logout, changePassword } from "../../actions/auth";
import Notifications from "../notifications"

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: {
        fullscreen: false,
        password: false,
      },
      password: {
        original: "",
        new: "",
        confirmed: "",
      },
    };
  }

  //const Navbar = ({ auth: { user }, logout, changePassword }) => {
  setNewPassword = (e) => {
    this.setState({
      ...this.state,
      password: { ...this.state.password, new: e },
    });
  };

  setConfirmedPassword = (e) => {
    this.setState({
      ...this.state,
      password: { ...this.state.password, confirmed: e },
    });
  };

  hideChangePassword = (e) => {
    this.setState({
      ...this.state,
      display: {
        ...this.state.display,
        password: false,
      },
    });
  };

  onClickSavePassword = () => {
    if (this.state.password.new === this.state.password.confirmed) {
      this.props.changePassword(this.props.user.uuid, this.state.password);
      this.hideChangePassword();
    }
  };

  isInFullScreenFn = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  toggleFullScreen = () => {
    const isFS = this.isInFullScreenFn();

    const docElm = document.documentElement;
    if (!isFS) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    this.setState({
      ...this.state,
      display: {
        fullscreen: !isFS,
        change: false,
      },
    });
  };

  render() {
    return (
      <nav id="nav" className="nav-container d-flex">
        <div className="nav-content d-flex">
          <div className="logo position-relative">
            <Link to="/"><div className="img" style={{width: "70em", backgroundImage: `url(/img/l/logo/${this.props?.profile?.smallLogoLight})`}} /></Link>
          </div>

          <div className="user-container d-flex">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="d-flex user position-relative" color="empty">
                  <img className="profile" alt="Profile" src={this.props.user?.avatar} />
                <div className="name">{this.props.user?.email}</div>
              </DropdownToggle>
              <DropdownMenu className="mt-3" end>
                <DropdownItem
                  onClick={(e) =>
                    this.setState({
                      ...this.state,
                      display: {
                        ...this.state.display,
                        password: true,
                      },
                    })
                  }
                >
                  Change password
                </DropdownItem>
                <DropdownItem onClick={this.props.logout}>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="menu-container flex-grow-1"></div>
          
          <Notifications />          

          <Modal
            isOpen={this.state.display.password}
            toggle={() => {
              this.setState({
                ...this.state,
                display: {
                  password: !this.state.display.password,
                  fullscreen: this.state.display.fullscreen,
                },
              });
            }}
            wrapClassName="modal-right"
          >
            <ModalHeader>Change Password</ModalHeader>
            <ModalBody>
              <div className="form-group col-md-12">
                <label className="text-muted text-small">
                  Current Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="originalPassword"
                  name="originalPassword"
                  value={this.state.password.original}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      password: {
                        ...this.state.password,
                        original: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="form-group col-md-12">
                <label className="text-muted text-small">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  value={this.state.password.new}
                  onChange={(e) => this.setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group col-md-12">
                <label className="text-muted text-small">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmedPassword"
                  name="confirmedPassword"
                  value={this.state.password.confirmed}
                  onChange={(e) => this.setConfirmedPassword(e.target.value)}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.onClickSavePassword}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={this.hideChangePassword}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          </div>
        
        <div className="nav-shadow"></div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  profile: state.licensee.profile
});

export default connect(mapStateToProps, { logout, changePassword })(Navbar);
