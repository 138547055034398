import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { format as formatDate } from "../../../utils/date";
import { deepEqual } from "../../../utils/object";

import { summary as getSummary } from "../../../actions/client";
import { currency as formatCurrency } from "../../../utils/number";

class Summary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.client, this.props?.client)) {
            this.fetchData();
        } else if (!deepEqual(old?.clock, this.props?.clock) || !deepEqual(old?.expense, this.props?.expense) || !deepEqual(old?.trust, this.props?.trust) || !deepEqual(old?.payment, this.props?.payment) || !deepEqual(old?.invoice, this.props?.invoice)) {
            this.fetchData();
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }

    fetchData = async () => {
        setTimeout( async () => {
            const _data = await this.props.getSummary(this.props.client);
            if (_data && _data.length) {
                this.setState({
                    trust: Number(_data[0].trust),
                    balance: Number(_data[0].balance),
                    invoiced: _data[0].latestInvoice, 
                    clocks: {
                        unbilled: Number(_data[0].clocks || 0)
                    },
                    expenses: {
                        unbilled: Number(_data[0].expenses || 0)
                    }
                });
            }
        }, 30);
    }


    render() {
        return (
            <Row>
                <Col xs="12">
                    <h2 className="small-title">Summary</h2>
                </Col>
                <Col xs="1"></Col>
                <Col xs="6">
                    <p>Last Invoiced</p>
                </Col>
                <Col xs="5" className="text-end">
                    <p className="text-end">{formatDate(this.state.invoiced) || "Never"}</p>
                </Col>
                <Col xs="1"></Col>
                <Col xs="6">
                    <p>Entrusted</p>
                </Col>
                <Col xs="5" className="text-end">
                    <p className="text-end">{formatCurrency(this.state.trust)}</p>
                </Col>
                <Col xs="1"></Col>
                <Col xs="6">
                    <p>Balance Due</p>
                </Col>
                <Col xs="5" className="text-end">
                    <p className="text-end">{formatCurrency(this.state.balance)}</p>
                </Col>
                <Col xs="1"></Col>
                <Col xs="6">
                    <p>Unbilled (Time)</p>
                </Col>
                <Col xs="5" className="text-end">
                    <p className="text-end">{formatCurrency(this.state.clocks?.unbilled || 0)}</p>
                </Col>
                <Col xs="1"></Col>
                <Col xs="6">
                    <p>Unbilled (Expenses)</p>
                </Col>
                <Col xs="5" className="text-end">
                    <p className="text-end">{formatCurrency(this.state.expenses?.unbilled || 0)}</p>
                </Col>
                {((this.state.trust - this.state.balance - (this.state.clocks?.unbilled || 0) - (this.state.expenses?.unbilled || 0)) < 0) ? (
                    <>
                        <Col xs="1"></Col>
                        <Col xs="6">
                            <p style={{color: "red", fontWeight: "bolder"}}>Shortage</p>
                        </Col>
                        <Col xs="5" className="text-end">
                            <p style={{color: "red", fontWeight: "bolder"}} className="text-end">{formatCurrency(this.state.trust - this.state.balance - (this.state.clocks?.unbilled || 0) - (this.state.expenses?.unbilled || 0))}</p>
                        </Col>
                    </>
                ) : (
                    <></>
                )}
            </Row>
        )
    }
}

Summary.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    trust: PropTypes.object,
    clock: PropTypes.object,
    expense: PropTypes.object,
    payment: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        trust: state.client.trust,
        clock: state.client.clock,
        expense: state.client.expense,
        payment: state.client.payment,
        invoice: state.client.invoice
    });
};

export default connect(mapStateToProps, { getSummary })(Summary);