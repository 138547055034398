import api from "../utils/api";
import {
  GET_COURTS, LOGOUT
} from "./types";
import { send as sendAlert } from "./alert";

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/courts`);
        dispatch({
            type: GET_COURTS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}