import React from "react";
import { connect } from "react-redux";
import {  Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { isGuid } from "../../../../utils/string";

import Case from "./case";
import Profile from "./profile";
import Staff from "./staff";
import Timecards from "./timecards";
import Expenses from "./expenses";
import Parties from "./parties";

class Matter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attorneyUuid: isGuid(this.props.attorneyUuid) ? this.props.attorneyUuid : null,
            description: this.props.description || "",
            courtUuid: this.props.courtUuid || "",
            caseNumber: this.props.caseNumber || "",
            typeCode: this.props.typeCode || "OT",
            partyCode: this.props.partyCode || "O",
            resultCode: this.props.resultCode || "IP",
            interval: this.props.interval || 6,
            representationStarted: this.props.representationStarted || Date.now(),
            representationEnded: this.props.representationEnded,
            MatterEmployees: this.props.MatterEmployees || [],
            MatterParties: this.props.MatterParties || [],
            Clocks: this.props.Clocks || [],
            Codes: {
                attorneys: this.props.Codes.attorneys ? this.props.Codes.attorneys : [],
                employees: this.props.Codes.employees ? this.props.Codes.employees : [],
                parties: this.props.Codes.parties ? this.props.Codes.parties : [],
                results: this.props.Codes.results ? this.props.Codes.results : [],
                types: this.props.Codes.types ? this.props.Codes.types : [],
                courts: this.props.Codes.courts ? this.props.Codes.courts : []
            },
            uuid: this.props.uuid || uuidv4()
        };
    }

    postChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        } 
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.postChange);
    }

    onRateChange = ({ uuid, rate }) => {
        let __found = false;
        const __employees = this.state.MatterEmployees || [];
        for (let i = 0; i < __employees?.length || 0; i++) {            
            if (__employees[i].employeeUuid === uuid) {
                __found = true;
                __employees[i].rate = rate;
            }
        }

        if (!__found) {
            __employees.push({
                matterUuid: this.state.uuid,
                employeeUuid: uuid,
                startDate: Date.now(),
                rate
            });
        }

        this.setState({
            ...this.state,
            MatterEmployees: __employees
        }, this.postChange);
    }

    onAssignmentChange = ({ uuid, assigned, rate }) => {
        let __found = false;
        const __employees = this.state.MatterEmployees || [];
        for (let i = 0; i < __employees?.length || 0; i++) {
            if (__employees[i].employeeUuid === uuid) {
                __found = true;
                __employees[i].endDate = assigned ? null : Date.now()
            }
        }

        if ((!__found) && (assigned)) {
            __employees.push({
                uuid: uuidv4(),
                matterUuid: this.state.uuid,
                employeeUuid: uuid,
                startDate: Date.now(),
                rate
            });
        }

        this.setState({
            ...this.state,
            MatterEmployees: __employees
        }, this.postChange);
    }

    onPartiesChange = (e) => {
        this.setState({
            ...this.state,
            MatterParties: e
        }, this.postChange);
    }


    render() {
        return (
            <>
                <Row>
                    <Col md="3" sm="12">
                        <Card body>
                            <Case representationEnded={this.state.representationEnded}
                                representationStarted={this.state.representationStarted}
                                attorneyUuid={this.state.attorneyUuid}
                                attorneys={this.state.Codes?.attorneys}
                                parties={this.state.Codes?.parties}
                                types={this.state.Codes?.types}
                                results={this.state.Codes?.results}
                                interval={this.state.interval}
                                typeCode={this.state.typeCode}
                                partyCode={this.state.partyCode}
                                resultCode={this.state.resultCode}
                                onChange={this.onChange}
                            />
                        </Card>
                        <Card body>
                            <Timecards clocks={this.state.Clocks}
                                onSelect={this.props.onClockSelect}
                            />
                            <Expenses matter={this.state.uuid}
                                onSelect={this.props.onExpenseSelect}
                            />
                        </Card>
                    </Col>
                    <Col md="9" sm="12">
                        <Card body className="mb-5">
                            <Profile description={this.state.description}
                                courtUuid={this.state.courtUuid}
                                caseNumber={this.state.caseNumber}
                                onChange={this.onChange}
                                courts={this.state.Codes.courts}
                            />
                        </Card>
                        <Card body className="mb-5">
                            <Parties items={this.state.MatterParties}
                                onChange={this.onPartiesChange}
                                matter={this.props.uuid}
                            />
                        </Card>
                        <Card body className="mb-5">
                            <Staff assigned={this.state.MatterEmployees}
                                onRateChange={this.onRateChange}
                                onAssignmentChange={this.onAssignmentChange}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

Matter.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps)(Matter);
