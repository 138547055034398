import React from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../layout/table";
import { table as getReports } from "../../actions/report";
import { format as formatDate } from "../../utils/date";
import ConditionalButton from "../../layout/conditional-button";

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "updatedAt",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0
        };
    }

    componentDidMount = async () => {
        this.fetchData();
    }

    fetchData = async () => {
        const __data = await this.props.getReports(this.state.page, this.state.size, this.state.order, this.state.direction, this.state.search);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "name",
                className: "text-muted text-small text-uppercase w-35",
                onFormat: this.onLinkFormat
            },
            {
                header: "Caption",
                sortable: true,
                source: "caption",
                className: "text-muted text-small text-uppercase w-25",
                onFormat: this.onLinkFormat
            },
            {
                header: "Category",
                sortable: true,
                source: "category",
                className: "text-muted text-small text-uppercase w-10"
            },
            {
                header: "Output",
                sortable: true,
                source: "outputCode",
                className: "text-muted text-small text-uppercase w-10"
            },
            {
                header: "Created",
                sortable: true,
                source: "createdAt",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onDateFormat
            },
            {
                header: "Updated",
                sortable: true,
                source: "updatedAt",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onDateFormat
            }
        ]
    }

    onDateFormat = (e) => {
        return formatDate(e, true);
    }

    onLinkFormat = (e, row) => {
        return `<a href="/dashboard/reports/${row['uuid']}">${e}</a>`
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    onNewClick = (e) => {
        this.props.history.push("/dashboard/reports/add");
    }

    render() {
        return (
            <>
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Create new report" display enabled={true} onEnabledClick={this.onNewClick} icon="flash">New</ConditionalButton>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                        onSearch={this.onSearch}
                        onSize={this.onSize}
                        searchable
                        sizable
                    />
                </Col>
            </>
        )
    }
}

Reports.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        items: state.licensee.reports
    });
};

export default connect(mapStateToProps, { getReports })(Reports);