import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row, Tab, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { JavaScripttoXML } from "../../../utils/serialize";

import ConditionalButton from "../../../layout/conditional-button";
import ConditionalDropdown from "../../../layout/conditional-dropdown";
import { isGuid } from "../../../utils/string";
import icalTk from "../../../utils/ics";
import { build, send } from "../../../utils/email";

import Personal from "./person";
import Lead from "./lead";
import Logs from "../../../layout/logs";
import Event from "../../../layout/event";
import Representation from "./representation";
import Calendar from "./calendar";

import { get as getLead, update as saveLead, convert as convertLead } from "../../../actions/lead";
import { list as getLogs, update as saveLog } from "../../../actions/log";
import { list as getReports, get as getReport, docx as generateDocx, pdf as generatePdf, named } from "../../../actions/report";
import { save as saveEvent, lead as getLeadEvents, destroy as deleteEvent } from "../../../actions/event";
import { list as getCodes, activities as getActivities } from "../../../actions/code";
import { list as getAttorneys } from "../../../actions/attorneys";
import { pack as setAlerts } from "../../../actions/alert";


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Person: {},
            selected: "profile",
            subject: "",
            retainer: 0.0,
            partner: 0.0,
            associate: 0.0,
            uuid: uuidv4(),
            modal: {
                event: {
                    show: false
                }
            },
            Codes: {
                attorneys: [],
                activities: []
            }
        };
        
    }

    componentDidMount = () => {
        setTimeout(() => {
            if (isGuid(this.uuid)) { this.loadData(); }
            this.loadSource();
        }, 30);
    }

    get uuid() {
        return this.props.match.params.uuid;
    }

    loadData = async () => {
        await this.props.getReports({ category: "Lead" });
        const data = await this.props.getLead(this.uuid);
        const { uuid, firstName, middleName, lastName, generation, email, Phone } = data?.Person;
        const { sourceCategory, subject, retainer, partner, associate, archiveDate, convertDate } = data;
        const Address = data?.Person?.PersonAddresses?.length > 0 ? data?.Person?.PersonAddresses[0]?.Address : {};
        const activities = await this.props.getActivities("Lead");
        const attorneys = await this.props.getAttorneys();

        const logs = await this.props.getLogs(this.uuid);
        this.setState({
            sourceCategory, subject, retainer, partner, associate, archiveDate, convertDate,
            uuid,
            Person: { uuid, firstName, middleName, lastName, generation, email, Phone, Address },
            logs,
            Codes: {
                activities,
                attorneys
            }
        });
    }

    loadSource = async () => {
        const sources = await this.props.getCodes("LS");
        this.setState({
            ...this.state,
            sources
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    onPersonChange = (value, errors) => {
        this.setState({
            ...this.state,
            Person: {
                ...value
            },
            errors: errors
        });
    }

    onLeadChange = (value) => {
        this.setState({
            ...this.state,
            ...value
        });
    }

    onSaveClick = async (e) => {
        const result = await this.props.saveLead
            (
                {
                    uuid: this.state.uuid,
                    sourceCategory: this.state.sourceCategory,
                    retainer: this.state.retainer,
                    partner: this.state.partner,
                    associate: this.state.associate,
                    subject: this.state.subject,
                    Person: this.state.Person
                }
            );
        if (result.success) {
            this.setState({
                Person: result.person
            });
            if (!isGuid(this.uuid)) {
                this.props.history.push(`/dashboard/leads/${this.state.uuid}`);
            }
        }
    }

    onEventSelect = async (e) => {
        const __event = await this.props.getLeadEvents(this.state.uuid, null, null, e);
        if (__event && __event.length) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        selected: __event[0],
                        show: true
                    }
                }
            });
        }
    }

    onEventOk = async (e) => {
        this.onEventHideModal(e);
        e.subjectUuid = this.state.uuid;
        e.path = "leads";
        const event = await this.props.saveEvent(e);

        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CONFIRMED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    onEventDelete = async (e,) => {
        this.onEventHideModal(e);
        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            const event = await this.props.getLeadEvents(this.state.uuid, null, null, e.uuid);

            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CANCELLED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        await this.props.deleteEvent({ uuid: e.uuid, subjectUuid: this.state.uuid, path: "leads" });

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    onEventHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    show: false
                }
            }
        })
    }

    onRecordEvent = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    show: true
                }
            }
        })
    }

    sendEventMail = async (toEmail, event, icsString) => {
        setTimeout(async () => {            
            const __fileName = `invite_${event.uuid}.ics`;
            const _xml = JavaScripttoXML(event); 
            const built = await build(_xml, "EVENT.SEND");
            await send(built.from, toEmail, built.subject, built.html, [{ filename: __fileName, encoding: 'utf8', content: icsString }]);
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        updated: Date.now()
                    }
                }
            })
        }, 30);
    }
 

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    onTabChange = (e) => {
        this.setState({
            ...this.state,
            selected: e
        });
    }

    onLogSave = async (e) => {
        e.targetUuid = this.state.uuid;
        e.sourceCode = "LE";
        e.addDate = Date.now();
        await this.props.saveLog(e);
        const __logs = this.state.logs;
        __logs.push({
            ...e,
            Person: {
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName
            }
        });

        this.setState({
            ...this.state,
            logs: __logs
        })
    }

    onReportClick = async (e, data) => {
        const _leads_object = {
            Person: {
                ...this.state.Person
            },
            sourceCategory: this.state.sourceCategory,
            retainer: this.state.retainer,
            partner: this.state.partner,
            associate: this.state.associate,
            subject: this.state.subject,
            uuid: this.state.uuid,
        }

        const _xml = JavaScripttoXML(_leads_object);
        if (data.outputCode === "RTF") {
            const _result = await this.props.generateDocx(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else if (data.outputCode === "PDF") {
            const _result = await this.props.generatePdf(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    get reportItems() {
        const result = [];
        this.props.reports?.forEach((item, index) => {
            result.push({ onClick: this.onReportClick, caption: item.caption, uuid: item.uuid, outputCode: item.outputCode, name: item.name, category: item.category, fileName: item.fileName, index })
        });
        return result;
    }

    onArchiveClick = async (e) => {
        const archiveDate = Date.now();

        await this.props.saveLead({
            uuid: this.state.uuid,
            archiveDate
        });
        this.setState({
            ...this.state,
            archiveDate
        });

    }

    onConvertClick = async (e) => {
        const convertDate = Date.now();

        await this.props.convertLead({
            uuid: this.state.uuid,
            trustAmount: this.state.retainer,
            sourceCategory: this.state.sourceCatetgory
        });

        this.setState({
            ...this.state,
            convertDate
        });

        this.props.history.push(`/dashboard/clients/${this.state.uuid}`);
    }

    onUnarchiveClick = async (e) => {
        const archiveDate = null;

        await this.props.saveLead({
            uuid: this.state.uuid,
            archiveDate
        });
        this.setState({
            ...this.state,
            archiveDate
        });

    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="8" className="float-right text-end">
                    <ConditionalDropdown className="ms-sm-1" enabledVariant="secondary" enabled display={(isGuid(this.uuid))} items={this.reportItems}>Generate</ConditionalDropdown>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Archive lead" display={((!this.state.convertDate) && (!this.state.archiveDate) && (isGuid(this.uuid)))} enabled={true} onEnabledClick={this.onArchiveClick} icon="close">Archive</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Activate lead" display={this.state.archiveDate && (isGuid(this.uuid))} enabled={true} onEnabledClick={this.onUnarchiveClick} icon="arrow-double-left">Unarchive</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Convert to client" display={((!this.state.convertDate) && (!this.state.archiveDate) && (isGuid(this.uuid)))} enabled={true} onEnabledClick={this.onConvertClick} icon="arrow-double-right">Convert</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Save my information" display={true} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                </Col>
                <Tab.Container activeKey={this.state.selected}>
                    <Card.Header className="border-0 pb-0">
                        <Nav className="nav-tabs-line card-header-tabs" variant="tabs" activeKey={this.state.selected}>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#profile"
                                    className={`${this.state.selected === 'profile' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('profile');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Profile
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#calendar"
                                    className={`${this.state.selected === 'calendar' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('calendar');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Calendar
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#notes"
                                    className={`${this.state.selected === 'notes' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('notes');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Col md="12">
                        <Form name="frm" action="post" onSubmit={this.onSubmit} >
                            <Row>
                                <Col>
                                    <Tab.Content className=" h-100">
                                        <Tab.Pane active={this.state.selected === 'profile'} className="h-100 scroll-out">
                                            <Card body className="mb-5">
                                                <Lead sources={this.state.sources} sourceCategory={this.state.sourceCategory} onChange={this.onLeadChange} />
                                                <Personal {...this.state.Person} onChange={this.onPersonChange} />
                                                <Representation subject={this.state.subject} retainer={this.state.retainer} partner={this.state.partner} associate={this.state.associate} onChange={this.onLeadChange} />
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane active={this.state.selected === 'calendar'} className="h-100 scroll-out">
                                            {this.state.selected === 'calendar' ? (
                                                <Card body className="mb-5">
                                                    <Calendar lead={this.state.uuid} onCreate={this.onRecordEvent} onSelect={this.onEventSelect} updated={this.state.event?.updated}></Calendar>
                                                </Card>
                                            ) : ``}
                                        </Tab.Pane>
                                        <Tab.Pane active={this.state.selected === 'notes'} className="h-100 scroll-out">
                                            <Card body className="mb-5">
                                                <Logs logs={this.state.logs} onSave={this.onLogSave} />
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Tab.Container>
                <Event
                    show={this.state.modal.event.show}
                    onDelete={this.onEventDelete}
                    onCancel={this.onEventHideModal}
                    onOk={this.onEventOk}
                    activities={this.state.Codes.activities}
                    employees={this.state.Codes.attorneys}
                    {...this.state.modal.event.selected}
                />
            </>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    reports: PropTypes.array,
    fields: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        reports: state.licensee.reports,
        fields: state.fields.items
    });
};

export default connect(mapStateToProps, { getLead, getAttorneys, saveEvent, deleteEvent, getActivities, getLeadEvents, saveLead, convertLead, getLogs, saveLog, getCodes, setAlerts, getReports, getReport, generateDocx, generatePdf, named })(Profile);
