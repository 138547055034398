import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { SET_INVOICE, LOGOUT } from "./types";


export const pending = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/clients/${uuid}/i/invoice/`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/clients/invoices/i/${uuid}`);
        return res?.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const update = (uuid, invoice) => async (dispatch) => {
    try {
        const res = await api.post(`/clients/${uuid}/invoices`, invoice);

        if (res?.data) {
            dispatch({
                type: SET_INVOICE,
                payload: res.data
            });
            return res?.data;
        }

    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const put = (uuid, fields) => async (dispatch) => {
    try {
        const res = await api.put(`/clients/${uuid}/invoices`, fields);

        if (res?.data) {
            dispatch({
                type: SET_INVOICE,
                payload: res.data
            });
            return res?.data;
        }

    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}