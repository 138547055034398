import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";
import ConditionalButton from "../../../layout/conditional-button";

import { format as formatDate } from "../../../utils/date";
import { deepEqual } from "../../../utils/object";
import { list as getCodes } from "../../../actions/code";
import { currency as formatCurrency } from "../../../utils/number";
import { payments as getPayments } from "../../../actions/client";

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "paymentDate",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.client, this.props?.client)) {
            this.fetchData();
        } else if (!deepEqual(old?.updated, this.props?.updated)) {
            this.fetchData();
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }


    fetchData = async () => {
        setTimeout( async () => {

            const __data = await this.props.getCodes("PS");
            const __payments = await this.props.getPayments(this.props.client, this.state.page, this.state.size, this.state.order, this.state.direction);
            let __count = 0;

            if ((__payments) && (__payments.length || 0 > 0))
                __count = Math.ceil(Number(__payments[0].count) / Number(this.state.size));

            this.setState({
                ...this.state,
                sources: __data,
                data: __payments,
                pages: __count
            });
        }, 30);
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "paymentDate",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onDateLinkFormat
            },
            {
                header: "Reference",
                sortable: true,
                source: "reference",
                className: "text-muted text-small text-uppercase w-25",
                onRender: this.onReferenceLinkFormat
            },
            {
                header: "Amount",
                sortable: true,
                source: "amount",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect({
            uuid: e.target.attributes["uuid"].value,
            clientUuid: e.target.attributes["client"].value,
            recordedByUuid: e.target.attributes["recorder"].value,
            amount: e.target.attributes["amount"].value,
            trust: e.target.attributes["trust"].value,
            paymentSource: e.target.attributes["source"].value,
            paymentDate: e.target.attributes["date"].value,
            reference: e.target.attributes["reference"].value,
        });
    }

    getSource = (value) => {
        let __result = this.state.sources.find(obj => {
            return obj.value === value;
        });
        return (__result ? __result.description : "");
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number(e));
    }

    onDateFormat = (e) => {
        return formatDate(e, true);
    }

    onDateLinkFormat = (e, row) => {
        return this.onLinkFormat(formatDate(e), row);
    }

    onLinkFormat = (e, row) => {
        return <a uuid={row.uuid.toString()}
            client={row.clientUuid.toString()}
            recorder={row.recordedByUuid.toString()}
            amount={row.amount.toString()}
            trust={row.trust.toString()}
            source={row.paymentSource.toString()}
            date={row.paymentDate.toString()}
            reference={row.reference.toString()}
            onClick={this.onItemClick}
            href="#"
            role="link">{e}</a>
    }

    onReferenceLinkFormat = (e, row) => {
        return this.onLinkFormat(`${e} (${this.getSource(row['paymentSource'])})`, row);
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }


    render() {
        return (
            <>
                <Col xs="12">
                    <Row>
                        <Col xs="6">
                            <h2 className="small-title">Payments</h2>
                        </Col>
                        {this.props.onCreate ? (
                            <Col xs="6" style={{ textAlign: "right" }}>
                                <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.props.onCreate} icon="plus"></ConditionalButton>
                            </Col>
                        ) : ``}
                    </Row>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Payments.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updated: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        updated: state.client.payment
    });
};

export default connect(mapStateToProps, { getCodes, getPayments })(Payments);