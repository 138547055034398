import api from "../utils/api";

import { send as sendAlert } from "./alert";
import { v4 as uuidv4 } from "uuid";
import { LOGOUT, SET_PAYMENT, SET_TRUST } from "./types";

export const update = (data) => async (dispatch) => {
    try {
        const res = await api.post(`/clients/${data.clientUuid}/payments/${data.uuid || uuidv4()}`, data);

        if (res?.data) {
            dispatch({
                type: SET_PAYMENT,
                payload: res.data
            });
            dispatch({
                type: SET_TRUST,
                payload: res.data
            });
            return res?.data;
        }

    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

