import React from "react";
import NumberFormat from "react-number-format";
import { Form, Col, Row } from "react-bootstrap";

import { v4 as uuidv4 } from 'uuid';

import { deepEqual } from "../../utils/object";

import CountrySelect, { exists as CountryValid } from "../select/country";
import StateSelect, { exists as StateValid } from "../select/state";
import CountySelect, { exists as CountyValid } from "../select/county";

class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            uuid: this.props.uuid || uuidv4(),
            country: this.props.country || "US"
        }
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps, this.props)) {
            this.setState({
                ...this.props
            });
        }
    }

    sendOnChange = (e) => {
        if (this.props.onChange) {
            let __address = { ...this.state };
            delete __address["onChange"];
            this.props.onChange(__address);
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.sendOnChange);
        
    }

    onCountryChange = (e) => {
        if (CountryValid(e.value)) {
            this.onChange({ target: { name: "country", value: e.value } });
        }
    }

    onStateChange = (e) => {
        if (StateValid(e.value)) {
            this.onChange({ target: { name: "state", value: e.value } });
        }
    }

    onCountyChange = (e) => {
        if (CountyValid(this.state.state, e.value)) {
            this.onChange({ target: { name: "county", value: e.value } });
        }
    }

    copyToClipboard = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
        } catch (error) {

        }
    }

    render() {
        return (
            <>
                <Row className={this.props.rowClassName}>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.line1 || ""} type="text" name="line1" onChange={this.onChange} />
                            <Form.Label style={{ cursor: "copy" }} onClick={() => this.copyToClipboard(this.state.line1)}>ADDRESS (Line 1)</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row className={this.props.rowClassName}>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control value={this.state.line2 || ""} type="text" name="line2" onChange={this.onChange} />
                            <Form.Label style={{ cursor: "copy" }} onClick={() => this.copyToClipboard(this.state.line2)}>ADDRESS (Line 2)</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row className={this.props.rowClassName}>
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <CountrySelect value={this.state.country || "US"} onChange={this.onCountryChange} placeholder="" name="country" />
                            <Form.Label>COUNTRY</Form.Label>
                        </div>
                    </Col>
                </Row>
                {(this.state.country === 'US') ? (
                    <Row className={this.props.rowClassName}>
                        <Col lg="4">
                            <div className="mb-3 top-label">
                                <Form.Control value={this.state.city || ""} type="text" name="city" onChange={this.onChange} />
                                <Form.Label style={{ cursor: "copy" }} onClick={() => this.copyToClipboard(this.state.city)}>CITY</Form.Label>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3 top-label">
                                <StateSelect value={this.state.state || ""} onChange={this.onStateChange} placeholder="" name="state" />
                                <Form.Label>STATE</Form.Label>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3 top-label">
                                <CountySelect value={this.state.county || ""} state={this.state.state} onChange={this.onCountyChange} placeholder="" name="county" />
                                <Form.Label>COUNTY</Form.Label>
                            </div>
                        </Col>                        
                        <Col lg="2">
                            <div className="mb-3 top-label">
                                <NumberFormat format="#####-####" mask="_" className="form-control" value={this.state.zipCode || ""} allowEmptyFormatting onChange={(e) => this.onChange({ target: { name: "zipCode", value: e.target.value } })} />
                                <Form.Label>ZIP CODE</Form.Label>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className={this.props.rowClassName}>
                        <Col lg="4">
                            <div className="mb-3 top-label">
                                <Form.Control value={this.state.city || ""} type="text" name="city" onChange={this.onChange} />
                                <Form.Label onClick={() => this.copyToClipboard(this.state.city)}>CITY</Form.Label>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3 top-label">
                                <Form.Control value={this.state.state || ""} type="text" name="state" onChange={this.onChange} />
                                <Form.Label>STATE or PROVINCE</Form.Label>
                            </div>
                        </Col>
                        <Col lg="2">
                            <div className="mb-3 top-label">
                                <Form.Control value={this.state.zipCode || ""} type="text" name="zipCode" onChange={this.onChange} />
                                <Form.Label>ZIP or POSTAL CODE</Form.Label>
                            </div>
                        </Col>
                    </Row>
                )}
            </>
        )
    }
}

export default Address;