
export const TImezoneArray = [
    {
        "TZID": "America/New_York",
        "X-LIC-LOCATION": "America/New_York",
        "DAYLIGHT": [
            {
                "TZOFFSETFROM": "-0500",
                "TZOFFSETTO": "-0400",
                "TZNAME": "EDT",
                "DTSTART": "19700308T020000",
                "RRULE": "FREQ=YEARLY;BYMONTH=3;BYDAY=2SU"
            }
        ],
        "STANDARD": [
            {
                "TZOFFSETFROM": "-0400",
                "TZOFFSETTO": "-0500",
                "TZNAME": "EST",
                "DTSTART": "19701101T020000",
                "RRULE": "FREQ=YEARLY;BYMONTH=11;BYDAY=1SU"
            }
        ]
    }
];

export default TImezoneArray;