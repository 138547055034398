import { SET_CLOCK, SET_PAYMENT, SET_TRUST, SET_EXPENSE, SET_INVOICE } from "../actions/types";

const initialState = {
    clock: {},
    payment: {},
    trust: {},
    expense: {},
    invoice: {}
};

function clientReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_CLOCK:
            return {
                ...state,
                clock: payload,
                loading: false,
            };
        case SET_PAYMENT:
            return {
                ...state,
                payment: payload,
                loading: false,
            };
        case SET_TRUST:
            return {
                ...state,
                trust: payload,
                loading: false,
            };
        case SET_EXPENSE:
            return {
                ...state,
                expense: payload,
                loading: false,
            };
        case SET_INVOICE:
            return {
                ...state,
                invoice: payload,
                loading: false,
            }
        default:
            return state;
    }
}

export default clientReducer;
