import React from "react";
import Tr from "./tbody.tr";

class FeraTbody extends React.Component {
    render() {
        return (
            <tbody>
                {this.props.page?.map((item, index) => {
                    return (
                        <Tr key={`tbody.tr.${index}`} item={item} headers={this.props.headers} />
                    );
                })}
            </tbody>
        )
    }
}

export default FeraTbody;