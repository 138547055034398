export const SET_ALERT = "SET_ALERT";
export const SET_ALERTS = "SET_ALERTS";
export const REM_ALERT = "REM_ALERT";
export const REM_ALERTS = "REM_ALERTS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADED = "SET_LOADED";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_FIELD_DATA = "GET_FIELD_DATA";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";
export const VERIFY_TOKEN_FAILED = "VERIFY_TOKEN_FAILED";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const GET_PERSON_DATA = "GET_PERSON_DATA";

export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";

export const SET_PAGE_INFO = "SET_PAGE_INFO";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEES = 'GET_EMPLOYEES';

export const GET_ATTORNEY = "GET_ATTORNEY";
export const GET_ATTORNEYS = "GET_ATTORNEYS";

export const GET_LEAD = "GET_LEAD";
export const GET_LEADS = "GET_LEADS";

export const GET_LOG = "GET_LOG";
export const GET_LOGS = "GET_LOGS";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORTS = "GET_REPORTS";

export const CLEAR_FILE = "CLEAR_FILE";
export const GET_FILE = "GET_FILE";
export const GET_FILES = "GET_FILES";
export const ADD_FILE = "ADD_FILE";   

export const SET_CLOCK = "SET_CLOCK"; 

export const SET_PAYMENT = "SET_PAYMENT";

export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";

export const SET_TRUST = "SET_TRUST";

export const SET_EXPENSE = "SET_EXPENSE";

export const SET_INVOICE = "SET_INVOICE";

export const GET_INTEGRATIONS = "GET_INTEGRATIONS";

export const GET_EVENTS = "GET_EVENTS";
export const SET_EVENT = "SET_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

export const GET_CHECK = "GET_CHECK";
export const GET_CHECKS = "GET_CHECKS";
export const NO_CHECKS = "NO_CHECKS";

export const GET_COURTS = "GET_COURTS";