import api from "../utils/api";
import { send } from "./alert";

import {
  LOGOUT, GET_FILES, ADD_FILE
} from "./types";

export const base64 = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/files/upload/s3/u/${uuid}/s/base64`);
        return res.data;
    } catch (err) {
        send(`Error retrieving file. ${err}`, "error");
    }
};

export const remove = (uuid) => async (dispatch) => {
    try {
        const res = await api.delete(`/files/upload/s3/u/${uuid}`);
        return res;
    } catch (err) {
        send(`Error retrieving file. ${err}`, "error");
    }
}