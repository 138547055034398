import React from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";
import { table as getClients } from "../../../actions/client";
import { format as formatDate } from "../../../utils/date";
import { currency } from "../../../utils/number";

import ConditionalButton from "../../../layout/conditional-button";

class Clients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "lastName",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            archived: 0,
            converted: 0,
            past: 0,
            active: 1
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }


    fetchData = async () => {
        const __data = await this.props.getClients(this.state.page, this.state.size, this.state.past, this.state.active, this.state.order, this.state.direction, this.state.search);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-50",
                onRender: this.onNameLinkFormat
            },
            {
                header: "Services",
                sortable: true,
                source: "clocks",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onServicesFormat
            },
            {
                header: "Trust",
                sortable: true,
                source: "trusts",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onCurrencyFormat
            }
        ]
    }

    onNameLinkFormat = (e, row) => {
        const balanceCheck = Number(row["trusts"]) - Number(row["balance"]) - Number(row["clocks"]) - Number(row["expenses"]) < 0 ?
            <span style={{ color: "red" }} title="insufficient trust balance">&#x1F3F2;</span> : <></>;
        if (row["Person.uuid"]) {
            return <a href={`/dashboard/clients/${row['uuid']}`}>{row["Person.lastName"]}, {row["Person.firstName"]} {balanceCheck}</a>
        } else {
            return <a href={`/dashboard/clients/${row['uuid']}`}>{row["Company.name"]} d/b/a {row["Company.dba"]} {balanceCheck}</a>
        }
    }

    onServicesFormat = (e, row) => {
        return currency(Number(row["clocks"]) + Number(row["expenses"]));
    }

    onCurrencyFormat = (e, row) => {
        return currency(Number(e));
    }


    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    onNewClick = (e) => {
        this.props.history.push("/dashboard/clients/add");
    }


    onPastChange = (e) => {
        this.setState({
            ...this.state,
            past: this.state.past === 1 ? 0 : 1
        }, this.fetchData);
    }

    render() {
        return (
            <>
                <Card body>
                    <Col xs="12">
                        <h2 className="small-title">Active Clients</h2>
                    </Col>
                    <Col xs="12">
                        <Table
                            headers={this.headers}
                            page={this.state.data}
                            sort={{ index: this.state.index, direction: this.state.direction }}
                            size={this.state.size}
                            index={this.state.page}
                            pages={this.state.pages}
                            onColumnClick={this.onColumnClick}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                </Card>
                
                <Card body className="mt-3">
                    <Row>
                        <Col xs="5"></Col>
                        <Col xs="4" style={{fontWeight: "bolder"}} className="text-end"> <span>Services </span></Col>
                        <Col xs="3" style={{fontWeight: "bolder"}} className="text-end">{currency(this.state?.data[0]?.totalClocks)}</Col>
                    </Row>
                    <Row>
                        <Col xs="5"></Col>
                        <Col xs="4" style={{fontWeight: "bolder"}} className="text-end"> <span>Expenses </span></Col>
                        <Col xs="3" style={{fontWeight: "bolder"}} className="text-end">{currency(this.state?.data[0]?.totalExpenses)}</Col>
                    </Row>
                </Card>
            </>
        )
    }
}

Clients.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        items: state.licensee.clients
    });
};

export default connect(mapStateToProps, { getClients })(Clients);