import api from "../utils/api";
import {
    GET_CHECKS,
    GET_CHECK,
    NO_CHECKS
} from "./types";


export const table = (page, size, order, direction, search) => async (dispatch) => {
    try {
        //(this.state.page, this.state.size, this.state.order, this.state.direction, this.state.search);
        ///checks/l/p/:page/s/:size/o/:order/:direction/:search?
        const res = await api.get(`/ledger/checks/l/p/${page}/s/${size}/o/${order}/${direction}/${search}`);
        dispatch({
            type: GET_CHECKS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: NO_CHECKS,
            payload: {
                msg: err && err.response && err.response.statusText,
                status: err && err.response && err.response.status,
            },
        });
    }
};

export const get = (uuid) => async (dispatch) => {
    try {
        //(this.state.page, this.state.size, this.state.order, this.state.direction, this.state.search);
        ///checks/l/p/:page/s/:size/o/:order/:direction/:search?
        const res = await api.get(`/ledger/checks/i/${uuid}`);
        dispatch({
            type: GET_CHECK,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: NO_CHECKS,
            payload: {
                msg: err && err.response && err.response.statusText,
                status: err && err.response && err.response.status,
            },
        });
    }
};

export const save = ({ uuid, payee, number, amount, date, memo }) => async (dispatch) => {
    
    try {
        const res = await api.post(`/ledger/checks/${uuid}`, {uuid, payee, number, amount, checkDate: date, memo});
        dispatch({
            type: GET_CHECK,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: NO_CHECKS,
            payload: {
                msg: err && err.response && err.response.statusText,
                status: err && err.response && err.response.status,
            },
        });
    }
}