
import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

export function exists(value) {
    return OutputArray.filter(obj => { return obj.Abbr === value });
}

export const OutputArray = [
  { Abbr: "PDF", Full: "Ouutput to PDF from Form" },
    { Abbr: "RTF", Full: "DOCX/DOC/RTF" },
  { Abbr: "XSL", Full: "XSL(T) -> PDF" }
];

const OutputData = (Full = true) => {
    const result = [];
    OutputArray.forEach((item) => {
        result.push({
            value: item.Abbr,
            label: Full ? item.Full : item.Abbr
        });
    });
    return result;
}

class OutputSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;
        
        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={OutputData(this.props.full).filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={OutputData(this.props.full)}
            >
            </Select>
        );
    }
};

export default OutputSelect;
