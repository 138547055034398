function format(date) {
  let the_date = new Date(date);
  let formatted_date =
    the_date.getHours() +
    1 +
    ":" +
    (the_date.getMinutes() < 10 ? "0" : "") +
    the_date.getMinutes();
  return date === null || isNaN(the_date.getMonth()) ? "" : formatted_date;
}

export { format };
