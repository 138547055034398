
import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";


class CodeSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e?.value) {
            this.setState({ value: e?.value || "" }, () => { this.state.onChange(e) });
        }
    }

    get options() {
        const __result = [];
        this.props.codes?.forEach(element => {
            __result.push({ value: element.value, label: element.description });
        });
        return __result;
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={this.options?.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={this.options}
                isClearable={this.props.isClearable}
            >
            </Select>
        );
    }
}


export default CodeSelect;