import React from "react";

import CsLineIcons from "../../../cs-line-icons";

class Label extends React.Component {
    render() {
        const __icon = this.props.icon;
        const __caption = this.props.caption;

        return (
            <>
                <span className="label" > {__caption}</span >
                {__icon ? (
                    <CsLineIcons icon={__icon} className="cs-icon icon" />
                ) : (
                    <></>
                )}
            </>
        )

    }
}

export default Label;