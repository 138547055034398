import React from "react";
import { connect } from "react-redux";
import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { deepEqual } from "../../../utils/object";
import { get as getReport, update as setReport } from "../../../actions/report";
import ConditionalButton from "../../../layout/conditional-button";
import ConditionalDropdown from "../../../layout/conditional-dropdown";
import OutputSelect from "../../../layout/select/report-output";
import { isGuid } from "../../../utils/string";

import FileModal from "../../../layout/dropzone-modal";
import { add as addFile, unzip, clean, base64 as asBase64 } from "../../../actions/file";
import { fields as pdfFields } from "../../../actions/report";

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                upload: {
                    show: false,
                },
            }
        }
    }

    get uuid() {
        return this.props.match.params.uuid;
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.loadData();
        }, 30);
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old, this.props)) {
            setTimeout(() => {
                this.loadData();
            }, 30);
        }
    }

    loadData = async () => {
        const __data = await this.props.getReport(this.uuid);
        this.setState({
            ...__data
        });
    }

    onSaveClick = async (e) => {
        await this.props.setReport(this.state)
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    onUploadHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                upload: {
                    ...this.state.modal.upload,
                    show: false
                }
            }
        })
    }

    onOkModal = async (e, file) => {
        const xmlNsRegEx = new RegExp("<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\"?>", 'g');
        this.onUploadHideModal(e);
        await this.props.addFile(file.file);

        if (file.file.extension === "docx") {
            const unzipped = await this.props.unzip(file.file.uuid);
            const body = [];
            body.push('<root version="1">');
            unzipped.files.forEach(file => {
                let folder = file.name;
                let name = file.base;
                folder = folder.substring(0, folder.length - name.length - 1);

                body.push(`<file folder="${folder}" name="${name}" transform="false" version="1.0" encoding="UTF-8" standalone="yes">`);
                body.push(file.data.replace(xmlNsRegEx, ""));
                body.push(`</file>`);
            });
            body.push('</root>');
            this.setState({
                category: "General",
                name: unzipped.name,
                caption: unzipped.name,
                outputCode: "RTF",
                template: body.join("\r\n"),
                uuid: uuidv4()
            });
            await this.props.clean(file.file.uuid);
        } else if (file.file.extension === "pdf") {
            const processed = await this.props.asBase64(file.file.uuid);
            const { name, data } = processed;
            const fields = await this.props.pdfFields({ form: data });

            const body = [];
            body.push('<root version="1">');
            body.push(`  <file name="${name}">${data}</file>`);
            body.push(`  <mapping>`);
            body.push(`    <xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform" xmlns:xml="http://www.w3.org/XML/1998/namespace">`);
            body.push(`      <xsl:output method="xml" indent="no" encoding="UTF-8"/>`)
            body.push(`      <xsl:template match="/">`);
            body.push(`        <xsl:text>[</xsl:text>`);
            fields.forEach((element, index) => {
                body.push(`          <xsl:text>{ "name": "${element.FieldName}", "value": "</xsl:text><xsl:value-of select="''"/><xsl:text>"}${ index !== fields.length-1 ? "," : ""}</xsl:text>`);
            });
            body.push(`        <xsl:text>]</xsl:text>`);
            body.push(`      </xsl:template>`);
            body.push(`    </xsl:stylesheet>`);
            body.push(`  </mapping>`);
            body.push('</root>');
            this.setState({
                category: "General",
                name: file.file.name,
                caption: file.file.name,
                outputCode: "PDF",
                template: body.join("\r\n"),
                uuid: uuidv4()
            });
            await this.props.clean(file.file.uuid);
        }
    }

    doUploadClick = (accept) => {

        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                upload: {
                    ...this.state.modal.upload,
                    show: true,
                    title: "Import Report Template",
                    body: "Select a template to create a report from.",
                    onCancel: this.onUploadHideModal,
                    onOk: this.onOkModal,
                    accept,
                    referenceUuid: isGuid(this.uuid) ? this.uuid : uuidv4(),
                    category: "reports"
                }
            }
        })
    }

    onImportDocxClick = (e) => {
        this.doUploadClick("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    }

    onImportPdfClick = (e) => {
        this.doUploadClick("application/pdf");
    }

    onOutputCodeChange = (e) => {
        this.onChange({ target: { name: "outputCode", value: e.value } });
    }


    get importItems() {
        const result = [];
        result.push({ onClick: this.onImportDocxClick, caption: "Word .docx", outputCode: "docx", index: 0 });
        result.push({ onClick: this.onImportPdfClick, caption: "Prefilled .pdf", outputCode: "pdf", index: 1 });
        return result;
    } 

    render() {
        return (
            <>
                <Col md="8" className="float-right text-end">
                    <ConditionalDropdown className="ms-sm-1" enabledVariant="secondary" enabled display items={this.importItems}>Import From</ConditionalDropdown>
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Save changes I've made" display enabled onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                </Col>
                <Col xs="12">
                    <Row className="g-3 pt-5 pb-3">
                        <Col lg="3">
                            <h2 className="small-title">Report Information</h2>
                        </Col>
                        <Col lg="9" className="text-end">
                        </Col>
                    </Row>
                    <Row className="g-3" style={{ paddingTop: "20px" }}>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">Category</div>
                        </Col>
                        <Col md="6" xl="7">
                            <Form.Control type="text" name="category" value={this.state?.category || ""} onChange={this.onChange} />
                        </Col>
                        <Col md="3" xl="3"></Col>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">Name</div>
                        </Col>
                        <Col md="6" xl="7">
                            <Form.Control type="text" name="name" value={this.state?.name || ""} onChange={this.onChange} />
                        </Col>
                        <Col md="3" xl="3"></Col>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">Caption</div>
                        </Col>
                        <Col md="6" xl="7">
                            <Form.Control type="text" name="caption" value={this.state?.caption || ""} onChange={this.onChange} />
                        </Col>
                        <Col md="3" xl="3"></Col>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">File Name Template</div>
                        </Col>
                        <Col md="6" xl="7">
                            <Form.Control type="text" name="fileName" value={this.state?.fileName || ""} onChange={this.onChange} />
                        </Col>
                        <Col md="3" xl="3"></Col>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">Output Style</div>
                        </Col>
                        <Col md="6" xl="7">
                            <OutputSelect value={this.state.outputCode || ""} onChange={this.onOutputCodeChange} placeholder="" name="outputCode" />
                        </Col>                                    
                        <Col md="3" xl="3"></Col>
                        <Col md="3" xl="2">
                            <div className="mb-3 h6 text-end">Template</div>
                        </Col>
                        <Col md="6" xl="7">
                            <Form.Control style={{fontFamily: "Courier"}} as="textarea" rows={30} wrap="off" name="template" value={this.state?.template || ""} onChange={this.onChange} />
                        </Col>
                        <Col md="3" xl="3"></Col>
                        
                    </Row>
                </Col>
                <FileModal {...this.state.modal.upload} />
            </>
        )
    }
}

Detail.propTypes = {
    auth: PropTypes.object.isRequired,
    report: PropTypes.object,
    profile: PropTypes.object
}

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        report: state.licensee.report,
        profile: state.licensee.profile
    })
}

export default connect(mapStateToProps, { getReport, setReport, addFile, unzip, clean, asBase64, pdfFields })(Detail);
