import React from "react";
import { Row, Col, Form, Accordion } from "react-bootstrap";
import ReactQuill from "react-quill";

import { format as formatDate } from "../../utils/date";
import { format as formatTime } from "../../utils/time";

import PrioritySelect from "../select/priority";
import ConditionalButton from "../conditional-button";

import "../../css/accordion.css";
import "react-quill/dist/quill.snow.css";

class Logs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            priority: 1,
            text: "",
            logs: this.props?.logs,
        };
    }

    get has() {
        return this.props?.logs?.length > 0;
    }

    postUpdate = () => {
        if (this.props.onChange) this.props.onChange({ ...this.state });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        }, this.postUpdate);
    };

    onTextChange = (e) => {
        this.onChange({
            target: {
                name: "text",
                value: e,
            },
        });
    };

    onPriorityChange = (e) => {
        this.onChange({ target: { name: "priority", value: e.value } });
    }

    onAddClick = (e) => {
        const { priority, text } = this.state;
        if (this.props.onSave) this.props.onSave({ priority, text });
        this.setState({
            priority: 0,
            text: ""
        });
    }

    render() {
        return (
            <>
                <Row className="g-3">
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <PrioritySelect value={this.state.priority || ""} onChange={this.onPriorityChange} placeholder="" name="priority" />
                            <Form.Label>PRIORITY</Form.Label>
                        </div>
                    </Col>
                    <Col lg="12">
                        <ReactQuill
                            name="text"
                            value={this.state?.text}
                            onChange={this.onTextChange}
                            style={{ height: "200px" }}
                        />
                    </Col>
                    <Col lg="12" style={{ marginTop: "75px" }}>
                        <ConditionalButton enabledVariant="outline-primary" display={true} enabled={true} onEnabledClick={this.onAddClick} icon="plus">Add</ConditionalButton>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <Accordion
                            className="col-12 mt-4"
                            style={{ marginBottom: "25px" }}
                        >
                            {this.props?.logs?.map((log, index) => (
                                <Accordion.Item
                                    eventKey={index}
                                    key={`log_accordion_item_${index}`}
                                    className="col-12 pt-2 pl-3"
                                    style={{ paddingLeft: "20px" }}
                                >
                                    <Accordion.Header>{`${formatDate(log.addDate)} ${formatTime(
                                        log.addDate
                                    )} by ${log.Person.firstName} ${log.Person.lastName
                                        }`}</Accordion.Header>
                                    <Accordion.Body dangerouslySetInnerHTML={{ __html: log.text }}></Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Logs;