import React from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";
import ConditionalButton from "../../../layout/conditional-button";

import { format as formatDate } from "../../../utils/date";
import { deepEqual } from "../../../utils/object";
import { list as getCodes } from "../../../actions/code";
import { currency as formatCurrency } from "../../../utils/number";
import { invoices as getInvoices } from "../../../actions/client";

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "invoiceGenerated",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            sources: [],
        };
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.client, this.props?.client)) {
            this.fetchData();
        } else if (!deepEqual(old?.updated, this.props?.updated)) {
            this.fetchData();
        }
    }

    componentDidMount = () => {
        this.fetchData();
    }


    fetchData = async () => {
        setTimeout( async () => {
            const __invoices = await this.props.getInvoices(this.props.client, this.state.page, this.state.size, this.state.order, this.state.direction);
            let __count = 0;

            if ((__invoices) && (__invoices.length || 0 > 0))
                __count = Math.ceil(Number(__invoices[0].count) / Number(this.state.size));

            this.setState({
                ...this.state,
                data: __invoices,
                pages: __count
            });
        }, 30);
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "invoiceGenerated",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onDateFormat
            },
            {
                header: "Previous",
                sortable: true,
                source: "previousBalance",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Current",
                sortable: true,
                source: "currentCharges",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Payments",
                sortable: true,
                source: "paymentsMade",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Outstanding",
                sortable: true,
                source: "totalBalance",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "",
                sortable: false,
                source: "uuid",
                className: "text-muted text-small text-uppercase w-20",
                onRender: this.onDownloadFormat
            }
        ]
    }

    onDownloadFormat = (e, row) => {
        return <>
            <a
                target="_blank"
                role="button"
                onClick={(e) => { this.props.onDownload(row["uuid"]) }}
                className="badge rounded-pill bg-info"
                style={{ marginLeft: "5px", paddingLeft: "15px", paddingRight: "15px" }}>pdf</a>
            <a
                role="button"
                onClick={(e) => { this.props.onSend(row["uuid"]) }}
                className="badge rounded-pill bg-info"
                style={{ marginLeft: "5px", paddingLeft: "15px", paddingRight: "15px" }}>{row["invoiceSent"] ? `sent ${formatDate(row["invoiceSent"])}` : `send`}</a>
            <a
                role="button"
                onClick={(e) => { this.props.onPublish(row["uuid"], row["invoicePublished"]) }}
                className="badge rounded-pill bg-info"
                style={{ marginLeft: "5px", paddingLeft: "15px", paddingRight: "15px" }}>{ row["invoicePublished"] ? `unpublish` : `publish` }</a>
        </>
    }

    onItemClick = (e) => {
        if (this.props.onSelect) this.props.onSelect({
            uuid: e.target.attributes["uuid"].value,
            clientUuid: e.target.attributes["client"].value,
            recordedByUuid: e.target.attributes["recorder"].value,
            amount: e.target.attributes["amount"].value,
            trust: e.target.attributes["trust"].value,
            paymentSource: e.target.attributes["source"].value,
            paymentDate: e.target.attributes["date"].value,
            reference: e.target.attributes["reference"].value,
        });
    }

    onCurrencyFormat = (e) => {
        return formatCurrency(Number(e));
    }

    onDateFormat = (e) => {
        return formatDate(e, false);
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);

    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }


    render() {
        return (
            <>
                <Col xs="12">
                    <Row>
                        <Col xs="6">
                            <h2 className="small-title">Invoices</h2>
                        </Col>
                        {this.props.onCreate ? (
                            <Col xs="6" style={{ textAlign: "right" }}>
                                <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.props.onCreate} icon="plus">Create Invoice</ConditionalButton>
                            </Col>
                        ) : ``}
                    </Row>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                    />
                </Col>
            </>
        )
    }
}

Invoices.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    updated: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        updated: state.client.invoice
    });
};

export default connect(mapStateToProps, { getCodes, getInvoices })(Invoices);