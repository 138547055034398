import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import IntlTelInput from "react-intl-tel-input";
import { v4 as uuidv4 } from "uuid";

import ConditionalButton from "../conditional-button";
import { isGuid } from "../../utils/string";
import { deepEqual } from "../../utils/object";

import GenerationsSelect from "../select/generation";
import CodeSelect from "../select/code";
import Address from "../address";

class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
        this.phoneRef = React.createRef();
    }

    get propsToState() {
        let __working = {
            ...this.props
        }

        const __companyUuid = uuidv4();

        const __result = {
            show: __working.show,
            partyType: __working.selected?.partyType || "OP",
            generation: __working.selected?.Person?.generation || "",
            uuid: isGuid(__working.selected?.uuid) ? __working.selected?.uuid : uuidv4(),
            prose: __working.selected?.Person?.prose || 0,
            save: __working.selected?.Person?.save || 0,
            barNumber: __working.selected?.Person?.barNumber || "",
            firstName: __working.selected?.Person?.firstName || "",
            middleName: __working.selected?.Person?.middleName || "",
            lastName: __working.selected?.Person?.lastName || "",
            email: __working.selected?.Person?.email || "",
            personUuid: isGuid(__working.selected?.Person?.uuid) ? __working.selected?.Person?.uuid : isGuid(__working.selected?.uuid) ? __working.selected?.uuid : uuidv4(),
            companyUuid: isGuid(__working.selected?.Person?.Company?.uuid) ? __working.selected?.Company?.uuid : __companyUuid,
            Phone: { 
                uuid: isGuid(__working.selected?.Person?.Phone?.uuid) ? __working.selected?.Person?.Phone?.uuid : isGuid(__working.selected?.Person?.uuid) ? __working.selected?.Person?.uuid : uuidv4(),
                country: __working.selected?.Person?.Phone?.country || "US",
                number: __working.selected?.Person?.Phone?.number || "",
                activityCode: __working.selected?.Person?.Phone?.activityCode || "AC"
            },
            Company: {
                uuid: isGuid(__working.selected?.Person?.Company?.uuid) ? __working.selected?.Company?.uuid : __companyUuid,
                name: __working.selected?.Person?.Company?.name
            },
            Address: {
                uuid: isGuid(__working.selected?.Person?.Address?.uuid) ? __working.selected?.Person?.Address?.uuid : isGuid(__working.selected?.Person?.uuid) ? __working.selected?.Person?.uuid : uuidv4(),
                line1: __working.selected?.Person?.Address?.line1 || "",
                line2: __working.selected?.Person?.Address?.line2 || "",
                city: __working.selected?.Person?.Address?.city || "",
                county: __working.selected?.Person?.Address?.county || "",
                zipCode: __working.selected?.Person?.Address?.zipCode || "",
                country: __working.selected?.Person?.Address?.country || "US"
            }
        }
        return __result;
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old, this.props)) {
            this.setState(this.propsToState);
        } else if (this.props.show && !old.show) {
            this.setState(this.propsToState);
        }
    }

    onGenerationChange = (e) => {
        this.onChange({ target: { name: "generation", value: e.value } });
    }

    onPartyTypeChange = (e) => {
        this.onChange({ target: { name: "partyType", value: e.value } });
    }

    onEmailBlur = (e) => {

    }

    onAddressChange = (e) => {
        this.setState({
            ...this.state,
            Address: {
                ...this.state.Address,
                ...e
            }
        }, this.sendOnChange);
    }

    onPhoneChange = (isValid, newNumber, country, fullNumber, extension) => {
        this.onChange({
            target: {
                name: "Phone",
                value: {
                    ...this.state.Phone,
                    country: country?.iso2?.toUpperCase() || "us",
                    number: fullNumber,
                    valid: isValid,
                }
            }
        });
        this.phoneRef.current.setState({ cursorPosition: fullNumber.length });
    }

    onProseChange = (e) => {
        this.onChange({
            target: {
                name: "prose",
                value: e.target.checked
            }
        })
    }

    onSaveChange = (e) => {
        this.onChange({
            target: {
                name: "save",
                value: e.target.checked
            }
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCompanyChange = (e) => {
        this.setState({
            ...this.state,
            Company: {
                ...this.state.Company,
                [e.target.name]: e.target.value
            }
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }
    
    onDelete = async (e) => {
        this.props.onDelete({
            uuid: this.state.uuid
        });
    }

    onOk = (e) => {
        const output = {
            uuid: this.state.uuid,
            partyType: this.state.partyType,
            personUuid: this.state.personUuid,
            matterUuid: this.state.matterUUid,
            Person: {
                generation: this.state.generation,
                prose: this.state.prose,
                save: this.state.save,
                barNumber: this.state.barNumber,
                firstName: this.state.firstName,
                middleName: this.state.middleName,
                lastName: this.state.lastName,
                email: this.state.email,
                companyUuid: this.state.companyUuid,
                Phone: {
                    ...this.state.Phone
                },
                Address: {
                    ...this.state.Address
                },
                Company: {
                    uuid: this.state.Company.uuid,
                    name: this.state.Company.name
                }
            }
        };

        this.props.onOk(output);
    }

    get canSave() {
        return (this.state.lastName?.length > 1) && (this.state.firstName?.length > 1) && (this.state.lastName?.length > 1)  ;
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="xl" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Party`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg="12">
                            <Row className="g-3">
                                <Col lg="12">
                                    <h2 className="small-title">Party</h2>
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col lg="6">
                                    <div className="top-label">
                                        <CodeSelect codes={this.props.types} value={this.state.partyType || "OP"} onChange={this.onPartyTypeChange} placeholder="" name="partyType" />
                                        <Form.Label>TYPE OF PARTY</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <Form.Check type="switch" id="prose" label="Pro Se" checked={this.state.prose} onChange={this.onProseChange} />
                                    <Form.Check type="switch" id="save" label="Save" checked={this.state.save} onChange={ this.onSaveChange } />
                                </Col>
                                <Col lg="4">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="text" name="barNumber" value={this.state.barNumber || ""} onChange={this.onChange} />
                                        <Form.Label>BAR #</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="g-3 mt-2">
                                <Col lg="12">
                                    <h2 className="small-title">Personal Information</h2>
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col lg="4">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="text" name="firstName" value={this.state.firstName || ""} onChange={this.onChange} />
                                        <Form.Label>FIRST NAME</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="text" name="middleName" value={this.state.middleName || ""} onChange={this.onChange} />
                                        <Form.Label>MIDDLE NAME</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="text" name="lastName" value={this.state.lastName || ""}  onChange={this.onChange} />
                                        <Form.Label>LAST NAME</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="2">
                                    <div className="mb-3 top-label">
                                        <GenerationsSelect value={this.state.generation || ""} onChange={this.onGenerationChange} placeholder="" name="generation" />
                                        <Form.Label>SUFFIX</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col lg="9">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="email" name="email" value={this.state.email || ""} onChange={this.onChange} onBlur={ this.onEmailBlur } />
                                        <Form.Label>EMAIL</Form.Label>
                                    </div>
                                </Col>
                                <Col lg="3">
                                    <div className="mb-3 top-label">
                                        <IntlTelInput ref={this.phoneRef} containerClassName="intl-tel-input" inputClassName="form-control" name="phone" id="phone" defaultCountry={this.state.Phone?.country.toLowerCase() || "us"} autoPlaceholder={false} value={this.state.Phone?.number || ""} onPhoneNumberChange={this.onPhoneChange} formatOnInit />
                                        <Form.Label style={{ left: "55px" }}>PHONE #</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="g-3">
                                <Col lg="12">
                                    <div className="mb-3 top-label">
                                        <Form.Control type="text" name="name" value={this.state.Company.name || ""} onChange={this.onCompanyChange} />
                                        <Form.Label>FIRM NAME</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="g-3">
                        <Col lg="12">
                            <h2 className="small-title">Address</h2>
                        </Col>
                    </Row>
                    <Address {...this.state.Address} onChange={ this.onAddressChange } />
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-danger" display enabled onEnabledClick={this.onDelete} icon="bin">delete</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">save</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);