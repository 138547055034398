import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import Select from "react-select";
import NumberFormat from "react-number-format";

import ConditionalButton from "../conditional-button";
import { isGuid } from "../../utils/string";
import { deepEqual } from "../../utils/object";
import { minutesBetween, addMinutes } from "../../utils/date";

import DatePickerEx from "../date-picker";


class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        const matteroptions = [];
        for (let i = 0; i < this.props.matters.length; i++) {
            const item = this.props.matters[i];
            matteroptions.push(
                { value: item.uuid, label: item.description, object: item }
            )
        }

        let __working = {
            ...this.props
        }

        if (this.props.matters && isGuid(this.props.uuids.matter) && !this.props.selected?.matter) {
            let __matter = this.props.matters.find(obj => {
                return obj.uuid === this.props.uuids.matter;
            });
            
            __working.selected = {
                ...__working.selected,
                matter: __matter
            }
        }

        const employeeoptions = [];
        for (let i = 0; i < __working.selected?.matter?.MatterEmployees.length; i++) {
            const item = __working.selected?.matter?.MatterEmployees[i];
            employeeoptions.push(
                { value: item.Employee.uuid, label: `${item.Employee.Person.firstName} ${item.Employee.Person.lastName} (${item.Employee.title})`, object: item }
            )
        }


        return {
            show: __working.show,
            matters: __working.matters || [],
            selected: {
                matter: __working.selected?.matter,
                uuids: {
                    client: __working.uuids?.client,
                    matter: __working.uuids?.matter,
                    employee: __working.uuids?.employee,
                    expense: __working.uuid
                },
            },
            options: {
                matters: matteroptions,
                employees: employeeoptions
            },
            notes: __working.notes || "",
            merchant: __working.merchant || "",
            category: __working.category || "UN",
            charge: __working.charge || 0,
            date: __working.date || Date.now(),
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old?.matters, this.props.matters)) {
            this.setState(this.propsToState);
        } else if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }

    onMatterChange = (e) => {
        const employeeoptions = [];
        for (let i = 0; i < e.object.MatterEmployees.length; i++) {
            const item = e.object.MatterEmployees[i];
            employeeoptions.push(
                { value: item.Employee.uuid, label: `${item.Employee.Person.firstName} ${item.Employee.Person.lastName} (${item.Employee.title})`, object: item }
            )
        }

        if (isGuid(e?.value)) {
            this.setState({
                ...this.state,
                options: {
                    ...this.state.options,
                    employees: employeeoptions
                },
                selected: {
                    ...this.state.selected,
                    matter: e.object,
                    uuids: {
                        ...this.state.selected.uuids,
                        matter: e?.value
                    }
                }
            });
        }
    }

    onEmployeeChange = (e) => {
        if (isGuid(e?.value)) {
            this.setState({
                ...this.state,
                selected: {
                    ...this.state.selected,
                    uuids: {
                        ...this.state.selected.uuids,
                        employee: e?.value
                    }
                }
            });
        }
    }

    onDateChange = (e) => {
        this.onChange({
            target: {
                name: "date",
                value: new Date(e)
            }
        });
    }

    onAmountChange = (e) => {
        this.onChange({ target: { name: "charge", value: e.floatValue } });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = (e) => {
        this.props.onOk({
            uuid: this.state.selected.uuids.clock,
            clientUuid: this.state.selected.uuids.client,
            matterUuid: this.state.selected.uuids.matter,
            employeeUuid: this.state.selected.uuids.employee,
            notes: this.state.notes,
            merchant: this.state.merchant,
            charge: this.state.charge,
            expenseDate: this.state.date,
            category: this.state.category
        });
    }

    get canSave() {
        return this.state.notes?.length > 1 && isGuid(this.state.selected.uuids.matter) && isGuid(this.state.selected.uuids.employee) && (this.state.charge > 0);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Expense`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12">
                            <div className="top-label">
                                <Select
                                    id="matterUuid"
                                    value={this.state.options.matters?.filter(option => option.value === this.state.selected?.uuids?.matter)}
                                    name="matterUuid"
                                    onChange={this.onMatterChange}
                                    classNamePrefix="react-select"
                                    placeholder="Matter"
                                    options={this.state.options.matters}
                                >
                                </Select>
                                <Form.Label>MATTER</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Select
                                    id="employeeUuid"
                                    value={this.state.options.employees?.filter(option => option.value === this.state.selected?.uuids?.employee)}
                                    name="employeeUuid"
                                    onChange={this.onEmployeeChange}
                                    classNamePrefix="react-select"
                                    placeholder="Employee"
                                    options={this.state.options.employees}
                                >
                                </Select>
                                <Form.Label>STAFF MEMBER</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="top-label">
                                <DatePickerEx
                                    id="date"
                                    selected={this.state.date}
                                    name="date"
                                    onChange={this.onDateChange}
                                    className="text-end form-control"
                                    dateFormat="MM/dd/yyyy"
                                />
                                <Form.Label>DATE OF</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="top-label">
                                <NumberFormat
                                    displayType="input"
                                    type="text"
                                    id="charge"
                                    name="charge"
                                    className="text-end form-control mb-2"
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    value={this.state.charge || 0}
                                    onValueChange={this.onAmountChange}
                                    removeFormatting
                                />
                                <Form.Label>AMOUNT</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="notes" value={this.state.notes || ""} onChange={this.onChange} />
                                <Form.Label>NOTES</Form.Label>
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="merchant" value={this.state.merchant || ""} onChange={this.onChange} />
                                <Form.Label>MERCHANT</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);