import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form, NavLink } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import Select from 'react-select';
import ConditionalButton from "../conditional-button";
import Table from "../table";
import CsLineIcons from "../../cs-line-icons";
import { deepEqual, isObject } from "../../utils/object";

import styles from "./style.css";

export const RolesCollection = {
    folder: {
        multiple: false,
        values: [
            {
                label: "View",
                value: 1,
                tags: ["read"]
            },
            {
                label: "Contribute",
                value: 2,
                tags: ["read", "add"]
            },
            {
                label: "Administer",
                value: 4,
                tags: ["read", "add", "delete"]
            }
        ]
    },
    file: {
        multiple: false,
        values: [
            {
                label: "View",
                value: 1,
                tags: ["read"]
            },
            {
                label: "Administer",
                value: 4,
                tags: ["read", "update", "delete"]
            }
        ]
    },
};



class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        const __role = this.props.options?.role ? this.props.options?.role : "file";
        return {
            caption: this.props.caption || `Share ${__role}...`,
            eligible: Array.isArray(this.props.eligible) ? [...this.props.eligible] : [],
            shares: Array.isArray(this.props.shares) ? [...this.props.shares] : [],
            roles: Array.isArray(this.props.options?.roles) ? [...this.props.options.roles] : RolesCollection[__role].values,
            multiple: isObject(this.props.options?.multiple) ? this.props.options?.multiple : RolesCollection[__role]?.multiple,
            default: isObject(this.props.options?.default) ? this.props.options?.default : 0,
            selected: [],
            level: 0,
            data: [],
            size: 10
        }
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old, this.props)) {
            this.setState(this.propsToState, this.refreshShares);
        }
    }

    componentDidMount = () => {
        setTimeout(async () => {
            this.refreshShares();
        }, 30);
    }


    get shareable() {
        const __result = [];
        for (let i = 0; i < this.state.eligible?.length || 0; i++) {
            const item = this.state.eligible[i];
            const __selected = this.state.selected.find(option => option.id === item.uuid);
            if (__selected) continue;
            const __share = this.state.shares.find(option => option.uuid === item.uuid);
            if (__share) continue;
            __result.push({ id: item.uuid, name: `${item.firstName} ${item.lastName}` });
        }
        return __result;
    }

    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "name",
                className: "text-muted text-small text-uppercase w-60"
            },
            {
                header: "Level",
                sortable: true,
                source: "level",
                className: "text-muted text-small text-uppercase w-30",
                onRender: this.onLevelRender
            },
            {
                header: "",
                sortable: true,
                source: "id",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onActionsRender
            }
        ]
    }

    onActionsRender = (e, row) => {
      
        return (
            <NavLink role="button" onClick={this.onShareDelete.bind(null, row["uuid"])} >
                <CsLineIcons height="16px" icon="bin" />
            </NavLink>);
    }

    onLevelRender = (e, row) => {
        return (
            <>
                {row.tags?.map((item, index) => {
                    return (
                        <span
                            role="button"
                            className="badge rounded-pill bg-info"
                            key={`tag_${item.replace(" ", "")}_${index}`}
                            style={
                                {
                                    marginLeft: "5px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px"
                                }
                            }>{item}</span>
                    );
                })}
            </>
        )
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            data: this.state.shares.slice(e*this.state.size, (e+1)*this.state.size),
            page: Number(e)
        });
    }

    refreshShares = () => {
        this.onPageChange(0);
    }

    onShareDelete = (e) => {
        const __shares = this.state?.shares;
        const __uuid = e;
        const __idx = __shares.findIndex(option => option.uuid === __uuid );
        __shares.splice(__idx, 1);

        this.setState({
            ...this.state,
            shares: [...__shares]
        }, this.refreshShares);

        if (this.props.onShareDelete) this.props.onShareDelete(__shares);
        if (this.props.onShareChange) this.props.onShareChange(__shares);
    }

    onShareAdd = () => {
        const __shares = this.state.shares;
        const __selected = this.state.selected;
        const __roles = this.state.roles;

        for (let i = 0; i < __selected.length; i++) {
            const __item = __selected[i];
            const __share = __shares.find(option => option.uuid === __item.id);
            const __tags = __roles.find(option => option.value === this.state.level).tags;
            if (__share) {
                __share.level = this.state.level;
                __share.tags = __tags;
            } else {
                __shares.push({ level: this.state.level, uuid: __item.id, name: __item.name, tags: __tags });
            }
        }

        this.setState({
            ...this.state,
            shares: [...__shares],
            selected: []
        }, this.refreshShares)

        if (this.props.onShareAdd) this.props.onShareAdd(__shares);
        if (this.props.onShareChange) this.props.onShareChange(__shares);
    }

    onSelectedDelete = (idx) => {
        if (idx < 0) return;

        const __selected = this.state?.selected;
        __selected.splice(idx, 1);
        this.setState({
            ...this.state,
            selected: [...__selected]
        });
    }

    onSelectedAdd = (tag) => {
        const __selected = this.state?.selected;
        __selected.push(tag);
        this.setState({
            ...this.state,
            selected: [...__selected]
        })
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    onLevelChange = (e) => {
        this.setState({
            ...this.state,
            level: e.value
        });
    }

    onCancel = async (e) => {
        this.props.onCancel(e);
    }
    
    onOk = (e) => {
        this.props.onOk(this.state.shares);
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.state.caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="8">
                            <div className="top-label">
                                <ReactTags
                                    multiple
                                    tags={this.state.selected}
                                    suggestions={this.shareable}
                                    onDelete={this.onSelectedDelete}
                                    onAddition={this.onSelectedAdd}
                                    placeholderText=""
                                    minQueryLength={0}
                                />
                                <Form.Label>SHARE WITH</Form.Label>
                            </div>
                        </Col>
                        <Col xs="3">
                            <div className="top-label">
                                <Select
                                    value={this.state.roles.find(option => option.value === (this.state.level || this.state.default || "0"))}
                                    name="level"
                                    onChange={this.onLevelChange}
                                    classNamePrefix="react-select"
                                    placeholder=""
                                    options={this.state.roles}
                                >
                                </Select>
                                <Form.Label>LEVEL</Form.Label>
                            </div>
                        </Col>
                        <Col xs="1" style={{ textAlign: "right" }}>
                            <ConditionalButton
                                enabledVariant="outline-primary"
                                style={
                                    { height: "100%" }
                                }
                                display
                                enabled={
                                    (this.state.level > 0) && (this.state.selected.length)
                                }
                                onEnabledClick={this.onShareAdd}
                                icon="save"
                            />
                        </Col>
                    
                    </Row>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12">
                            <Table
                                headers={this.headers}
                                page={this.state.data}
                                sort={{ index: this.state.index, direction: this.state.direction }}
                                size={this.state.size}
                                index={this.state.page}
                                pages={this.state.pages}
                                onColumnClick={this.onColumnClick}
                                onPageChange={this.onPageChange}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onOk} icon="ok">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);