import React from "react";
import { connect } from "react-redux";
import { Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

import Display from "./display";

class Calendar extends React.Component {
    render() {
        return (
            <>
                <Row>
                    <Col xs="12">
                        <Display lead={this.props.lead} onSelect={this.props.onSelect} updated={this.props.updated}  onCreate={this.props.onCreate}/>
                    </Col>
                </Row>
            </>
        )
    }
}

Calendar.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps)(Calendar);
