import api from "../utils/api";
import {
  GET_LOG, GET_LOGS, LOGOUT
} from "./types";
import { send as sendAlert } from "./alert";


export const update = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/logs`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/logs/i/${uuid}`);
        dispatch({
            type: GET_LOG,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = (targetUuid) => async (dispatch) => {
    try {
        const res = await api.get(`/logs/t/${targetUuid}`);
        dispatch({
            type: GET_LOGS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}