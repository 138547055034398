import React from "react";
import { Form, Col, Row } from 'react-bootstrap';

import { deepEqual } from "../../../utils/object";
import CodeSelect from "../../../layout/select/code";

class Lead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(old, this.props)) {
            this.setState({
                ...this.props
            })
        }
    }

    postUpdate = () => {
        if (this.props.onChange) {
            const { sourceCategory } = this.state;

            this.props.onChange({
                sourceCategory
            })
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        }, this.postUpdate)
    }

    onSourceChange = (e) => {
        this.onChange({
            target: {
                name: "sourceCategory",
                value: e.value
            }
        })
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg="12">
                        <Row className="g-3">
                            <Col lg="12">
                                <div className="mb-3 top-label">
                                    <CodeSelect codes={ this.props.sources } value={this.state.sourceCategory || ""} onChange={this.onSourceChange} placeholder="" name="sourceCategory" />
                                    <Form.Label>SOURCE</Form.Label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Lead; 