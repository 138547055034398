import React, { Suspense, useRef } from "react";
import { connect } from "react-redux";
import {  Card, Col, Row, Button, Dropdown } from 'react-bootstrap';
import PropTypes from "prop-types";
import CsLineIcons from "../../../cs-line-icons";
import { v4 as uuidv4 } from "uuid";

import { formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import { get as getEvents } from "../../../actions/event";


class Display extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedView: props.selectedView || "dayGridMonth",
            dateTitle: props.dateTitle || "",
            events: []
        }
        this.calendarRef = React.createRef();
    }

    componentDidMount = () => {
        this.__isMounted = true;
        const __currentRange = this.calendarRef.current.getApi().currentDataManager.state.dateProfile.activeRange;

        setTimeout(() => {
            this.handleDates({
                startStr: __currentRange.start,
                endStr: __currentRange.end
            });
        }, 30);
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.updated !== this.props.updated) {
            this.componentDidMount();
        }
    }


    componentWillUnmount = () => {
        this.__isMounted = false;
    }


    renderCalendar = () => {
        this.calendarRef.current.getApi().updateSize();
    }

    handleDates = async (rangeInfo) => {
        if (!this.__isMounted) return;

        try {
            const events = await this.props.getEvents(Date.parse(rangeInfo.startStr), Date.parse(rangeInfo.endStr), "x", "x");
            const eventData = [];

            for (let i = 0; i < events.length; i++) {
                const item = events[i];

                eventData.push({
                    ...item,
                    id: item.uuid,
                    title: item.caption,
                    start: Date.parse(item.startDate),
                    end: Date.parse(item.endDate),
                    backgroundColor: item.Activity.backgroundColor || "inherit",
                    borderColor: item.Activity.borderColor || "inherit"
                });
            }
            this.setState({
                ...this.state,
                events: eventData
            }, this.renderCalendar)
        } catch (e) {
            console.log('This action could not be completed');
        }
    }

    handleDoubleClick = async (e) => {
        if (this.props.onSelect) this.props.onSelect(e);
    }

    viewDidMount = ({ view }) => {
        this.setState({
            ...this.state,
            dateTitle: view.title
        });
    }

    openSubject = (path, uuid) => {
        this.props.history.push(`/dashboard/${path}/${uuid}`);
    }

    
    renderEventContent = (eventInfo) => {
        const { timeText, backgroundColor, borderColor } = eventInfo;
        const { allDay, title, id } = eventInfo.event;

        const evt = this.state.events.find(option => option.uuid == eventInfo.event.id);


        if (!allDay) {
            return (
                <>
                    <Row>
                        <Col xs="12" onDoubleClick={() => this.handleDoubleClick(id)}>
                            <div  style={{ verticalAlign: "middle" }}>
                                <span className="fc-daygrid-event-dot" style={{ display: "inline-block", backgroundColor, borderColor }} />
                                <span className="fc-event-time" style={{}}>{timeText}</span>
                                <span className="fc-event-title" style={{}}>{title}</span>
                            </div>
                        </Col>
                        <Col xs="12" style={{marginLeft: "30px"}} onDoubleClick={() => this.handleDoubleClick(id)}> 
                            {evt.location}
                        </Col>
                        <Col xs="12" style={{marginLeft: "30px"}} onClick={() => this.openSubject(evt.Client?.uuid ? "clients" : "leads", evt.subjectUuid)}>
                            {evt.Client?.Person ? `${evt.Client.Person.lastName}, ${evt.Client.Person.firstName}` : evt.Lead?.Person ? `${evt.Lead.Person.lastName}, ${evt.Lead.Person.firstName}` : ``}
                            <CsLineIcons height="12px" icon="edit-square" />
                        </Col>
                    </Row>
                </>
            );
        }
        return (
            <>
                <div className="fc-event-main-frame">
                    <div className="fc-event-title-container">
                        <div className="fc-event-title fc-sticky">{title}</div>
                    </div>
                </div>
            </>
        );
    }


    render() {
        return (

            <>
                
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
                    headerToolbar={{
                        left: 'today',
                        right: 'prev,next',
                        center: 'title'
                    }}
                    initialView="dayGridDay"
                    themeSystem="bootstrap"
                    editable
                    selectable
                    selectMirror
                    dayMaxEvents
                    weekends
                    datesSet={this.handleDates}
                    eventContent={this.renderEventContent}
                    viewDidMount={this.viewDidMount}
                    events={this.state.events}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                    }}
                />
            </>
        )
    }
}

Display.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps, { getEvents })(Display);
