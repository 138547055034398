import api from "../utils/api";
import {
  GET_ATTORNEY, GET_ATTORNEYS, LOGOUT
} from "./types";
import { send as sendAlert } from "./alert";


export const save = (value) => async (dispatch) => {
    try {
        const res = await api.post(`/attorneys`, value);
        dispatch(sendAlert("Saved succesfully", "success"));
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/attorneys/i/${uuid}`);
        dispatch({
            type: GET_ATTORNEY,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const list = () => async (dispatch) => {
    try {
        const res = await api.get(`/attorneys/l`);
        dispatch({
            type: GET_ATTORNEYS,
            payload: res.data,
        });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}