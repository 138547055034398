import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

const DEFAULT_IMAGE = "iapp-Bill11";
const COLOR_ARRAY = [
    "#B4654A",
    "#C2C5BB",
    "#77B28C",
    "#157A6E",
    "#499F68"
];

export function exists(value) {
    return PriorityData.filter(obj => { return obj.value === value });
}

export function label(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].label : "Other";
}

export function image(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].image : DEFAULT_IMAGE;
}

export function color(value) {
    const __data = exists(value);
    return __data?.length > 0 ? __data[0].color : COLOR_ARRAY[0];
}


export const PriorityData = [
    { value: "0", label: "Low", image: DEFAULT_IMAGE, color: COLOR_ARRAY[0] },
    { value: "1", label: "Normal", image: DEFAULT_IMAGE, color: COLOR_ARRAY[1] },
    { value: "2", label: "High", image: DEFAULT_IMAGE, color: COLOR_ARRAY[1] }
];

class PrioritySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, this.sendOnChange);
        }
    }

    sendOnChange = () => {
        if (this.props.onChange) this.props.onChange({ value: this.state.value });
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={PriorityData.filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={PriorityData}
                
            >
            </Select>
        );
    }
};

export default PrioritySelect;
