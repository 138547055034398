import React from "react";
import { connect } from "react-redux";
import {  Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { isGuid } from "../../../../utils/string";

import ConditionalButton from "../../../../layout/conditional-button";
import ConditionalDropdown from "../../../../layout/conditional-dropdown";
import Dropzone from "../../../../layout/dropzone";
import Folder from "../../../../layout/folder";

import Items from "./items";
import { update as saveFolder, contents as getFiles } from "../../../../actions/folder";
import { base64 as downloadS3, remove as destroyS3 } from "../../../../actions/s3";
import { archive, unarchive, eligible, share } from "../../../../actions/file";
import Modal from "../../../../layout/modal";
import Shares, { RolesCollection } from "../../../../layout/share";

class Files extends React.Component {
    constructor(params) {
        super(params);

        this.state = {
            path: "S3:\\",
            // permissions
            // 2^0  1   firm
            // 2^1  2   client
            // 2^2  4   custom 
            permission: 1,
            modal: {
                folder: {
                    show: false
                },
                destroy: {
                    show: false
                },
                error: {
                    show: false
                },
                share: {
                    show: false
                }
            },
            contents: [],
            selected: []
        }
    }


    componentDidMount = async () => {
        this.fetchData();
    }

    ///upload/r/:referenceUuid/p/:permissions
    fetchData = async () => {
        setTimeout(async () => {

            const __data = await this.props.getFiles({ referenceUuid: this.props.client, path: this.state.path });
            const __eligible = await this.props.eligible(this.props.client);

            this.setState({
                ...this.state,
                contents: [...__data],
                eligible: [...__eligible]
            });
                    
        }, 30);
    }

    get selected() {
        const result = [];
        result.push({ onClick: this.onArchiveFiles, caption: "Archive" });
        result.push({ onClick: this.onDestroyFiles, caption: "Destroy" });
        result.push({ onClick: this.onShareFiles, caption: "Share" });
        return result;
    }

    get shares() {
        return [
            {
                uuid: uuidv4(),
                firstName: "test",
                lastName: "test",
                flags: 7
            }
        ]
    }

    onArchiveFiles = async () => {
        await this.props.archive(this.state.selected, 1);
        await this.fetchData();
    }

    onShareFiles = async (e) => {
        const __selected = this.state.selected;
        const __shares = [];

        for (let i = 0; i < __selected.length; i++) {
            const __item = __selected[i];
            const __content = this.state.contents.find(option => option.uuid === __item);
            for (let j = 0; j < __content.FileShares.length; j++) {
                const __share = __content.FileShares[j];
                const __person = this.state.eligible.find(option => option.uuid === __share.personUuid);
                const __item = __shares.find(option => option.uuid === __share.personUuid);
                
                if (__item) {
                    __item.level = __share.flags > __item.level ? __share.flags : __item.level;
                    __item.tags = RolesCollection["folder"].values.find(option => option.value === __item.level).tags;
                } else {
                    const __role = RolesCollection["folder"].values.find(option => option.value === __share.flags);
                    __shares.push({ level: __share.flags, uuid: __share.personUuid, name: `${__person.firstName} ${__person.lastName}`, tags: __role.tags });
                }
            }
        }

        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                share: {
                    ...this.state.modal.share,
                    show: true,
                    shares: [...__shares]
                }
            }
        })
    }

    onShareHideModal = async (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                share: {
                    ...this.state.modal.share,
                    show: false
                }
            }
        })
    }

    onDestroyFiles = async (e) => {
        e.preventDefault();
        const __selected = this.state.selected;
        let __directory_not_empty = false;
        for (let i = 0; i < __selected.length; i++) {
            const uuid = __selected[i];
            const __item = this.state.contents.find(item => ((item.uuid === uuid) && (item.contentType === "directory")));
            if (!__item) continue;
            const __data = await this.props.getFiles({ referenceUuid: this.props.client, path: __item.path });
            __directory_not_empty = (__data?.length > 0);
            if (__directory_not_empty) break;
        }

        if (__directory_not_empty) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    error: {
                        show: true,
                        title: 'Error',
                        body: `The selection contains a folder that is not empty. Please empty the folder before attempting to delete. Click ok to return to editor.`,
                        data: { ...e }
                    }
                }
            });

            return;
        }
        
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    show: true,
                    title: 'Confirm destruction',
                    body: `Confirm destruction of these files. This will permanently remove these files from storage. This action cannot be undone. Click ok to continue or cancel to return to editor.`,
                    data: { ...e }
                }
            }
        })
    }

    onDestroyHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    ...this.state.modal.destroy,
                    show: false
                }
            }
        })
    }

    onDestroyOk = async (e) => {
        this.onDestroyHideModal(e);
        const __selected = this.state.selected;

        for (let i = 0; i < __selected.length; i++) {
            const item = __selected[i];
            await this.props.destroyS3(item);
        }
        await this.fetchData();
        
    }

    onErrorOk = async (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                error: {
                    ...this.state.modal.error,
                    show: false
                }
            }
        })        
    }

    onFolderOk = async (e) => {
        this.onFolderHideModal(e);
        const __folder = await this.props.saveFolder
            (
                {
                    referenceUuid: this.props.client,
                    permissions: 1 + ((e.share.client || 0) * 2),
                    path: `${this.state.path.replace("S3:", "")}${e.name}\\`,
                    uuid: e.uuid
                }
            );
        
        this.setState({
            ...this.state,
            contents: [...this.state.contents, __folder]
        });
    }

    onShareOk = async (e) => {
        this.onShareHideModal(e);
        await this.props.share({
            clientUuid: this.props.client,
            files: this.state.selected,
            shares: [...e]
        });
        await this.fetchData();
    }

    
    onFolderHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                folder: {
                    ...this.state.modal.folder,
                    show: false
                }
            }
        })
    }

    onFolderCreate = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                folder: {
                    ...this.state.modal.folder,
                    show: true
                }
            }
        })
    }

    onCheckedItem = async (options) => {
        const __selected = this.state.selected;
        const idx = __selected.indexOf(options.uuid);

        if (idx === -1) {
            __selected.push(options.uuid);
        } else {
            __selected.splice(idx, 1);
        }

        this.setState({
            ...this.state,
            selected: [
                ...__selected
            ]
        });
    }

    onOpenItem = async (options) => {
        if (options.extension === "__dir") {
            this.setState({
                ...this.state,
                path: options.path,
                selected: []
            }, this.fetchData)
        } else {
            const _result = await this.props.downloadS3(options.uuid);
            const linkSource = `data:${options.contentType};base64,${_result.data}`;
            const link = document.createElement("a");

            link.href = linkSource;
            link.setAttribute(
                'download',
                options.name,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    onPathUp = () => {
        const __array = this.state.path.split("\\");
        __array.pop();
        __array.pop();
        const __path = __array.join("\\");
        this.setState({
                ...this.state,
                path: __path +"\\"
        }, this.fetchData)
    }

    onFileUpload = (e) => {
        this.setState({
            ...this.state,
            contents: [...this.state.contents, e]
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs="12" xl="4">
                        <Dropzone label="Upload file" autoRemove onFileUpload={ this.onFileUpload } url={`files/upload/s3/r/${this.props.client}/c/File/p/${this.state.permission}?path=${this.state.path}`} />
                    </Col>
                    <Col xs="12" xl="8">
                        <Row>
                            <Col xs="6">
                                <h2 style={{ height: "var(--small-title-height)", color: "var(--primary)", marginTop: "5px" }}>{this.state.path.replace("S3:", "") === "\\" ? "[Home]" : this.state.path.replace("S3:", "")}
                                </h2>
                            </Col>
                            <Col xs="6" style={{ textAlign: "right" }}>
                                <ConditionalButton enabledVariant="outline-primary" display={ this.state.path !== "S3:\\" } enabled onEnabledClick={this.onPathUp} icon="arrow-top-left"></ConditionalButton>
                                <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onFolderCreate} icon="folder">Create Folder</ConditionalButton>
                                <ConditionalDropdown className="ms-sm-1" enabledVariant="outline-primary" disabledVariant="secondary" enabled={ this.state.selected.length >= 1 } display items={this.selected}>Selected</ConditionalDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Items selected={ this.state.selected } files={this.state.contents} onOpen={this.onOpenItem} onChecked={ this.onCheckedItem } />                            
                        </Row>
                    </Col>
                </Row>

                <Folder
                    show={this.state.modal.folder.show}
                    date={this.state.modal.folder.name || ""}
                    path={this.state.path}
                    parties={this.shares}
                    uuid={this.state.modal.folder.uuid}
                    onCancel={this.onFolderHideModal}
                    onOk={this.onFolderOk}
                />
                
                <Modal
                    show={this.state.modal.destroy.show}
                    title={this.state.modal.destroy.title}
                    body={this.state.modal.destroy.body}
                    buttons={[{
                        default: true,
                        caption: "cancel",
                        onClick: this.onDestroyHideModal,
                        variant: "secondary"
                    },
                    {
                        caption: "ok",
                        onClick: this.onDestroyOk,
                        variant: "outline-primary"
                    }]} /> 
                
                <Modal
                    show={this.state.modal.error.show}
                    title={this.state.modal.error.title}
                    body={this.state.modal.error.body}
                    buttons={[
                    {
                        caption: "ok",
                        onClick: this.onErrorOk,
                        variant: "outline-primary"
                    }]} /> 
                
                <Shares
                    show={this.state.modal.share.show}
                    onCancel={this.onShareHideModal}
                    onOk={this.onShareOk}
                    eligible={this.state.eligible}
                    shares={this.state.modal.share.shares}
                /> 
            </>
        )
    }
}

Files.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps, { saveFolder, getFiles, downloadS3, archive, unarchive, destroyS3, eligible, share })(Files);
