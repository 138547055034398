import React from "react"

import Item from "./item";

class items extends React.Component
{
    render() {
        return (
            (!this.props.items?.length) ?  `` :
            this.props.items.map((item, index) => {
                return (item.display.indexOf("Mobile") >= 0) ? <Item key={`menu.${item.id}.${index}`} id={item.id} item={item} /> : ``
            })
        )
    }
}
    
export default items;