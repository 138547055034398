export function template(templateid, data) {
  return document.getElementById(templateid).innerHTML.replace(
    /%(\w*)%/g, // or /{(\w*)}/g for "{this} instead of %this%"
    function (m, key) {
      return data.hasOwnProperty(key) ? data[key] : "";
    }
  );
}

export function compare(s1, s2, insensitive) {
  let _string_a = insensitive ? s1.toLowerCase() : s1;
  let _string_b = insensitive ? s2.toLowerCase() : s2;
  return _string_a.localeCompare(_string_b);
}

export function right(value, length, defaultValue) {
  return value?.length >= length ? value.substr(value.length - length, value.length) : defaultValue || "";
}

export function left(value, length, defaultValue="") {
  return value?.length >= length ? value.substr(0, length): defaultValue || "";
}


export function ssn(value, obscured) {
  return value.length === 11
    ? obscured
      ? "XXX-XX-" + right(value, 4)
      : value
    : "(unknown)";
}

export function phone(value) {
  let cleaned = ("" + value).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[2] + ") " + match[3] + "-" + match[4];
  }
  match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function isGuid(value) {
  var pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return value?.match(pattern);
}
