import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { deepEqual } from "../../../utils/object";
import { calculate } from "../../../utils/validation";

import 'react-intl-tel-input/dist/main.css';
import 'react-datepicker/dist/react-datepicker.css';

class Representation extends React.Component {

    static defaultProps = () => {
        return {
            subject: "",
            retainer: 0.0,
            partner: 0.0,
            associate: 0.0
        }
    }
    
    constructor(props) {
        super(props);
        this.state = ({
            ...this.defaultProps,
            ...props,
        })
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                });
            } 
            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "LEAD", this.state));
        }
    }

    render() {
        return (
            <>
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">Representation Information</h2>
                    </Col>
                </Row>                
                <Row className="g-3">
                    <Col lg="12">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="subject" value={this.state.subject || ""} onChange={this.onChange} />
                            <Form.Label>SUBJECT</Form.Label>
                        </div>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="retainer" value={this.state.retainer || ""} onChange={this.onChange} />
                            <Form.Label>RETAINER AMOUNT</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="partner" value={this.state.partner || ""} onChange={this.onChange} />
                            <Form.Label>PARTNER RATE</Form.Label>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="mb-3 top-label">
                            <Form.Control type="text" name="associate" value={this.state.associate || ""}  onChange={this.onChange} />
                            <Form.Label>ASSOCIATE RATE</Form.Label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

Representation.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Representation);

