import React from "react";
import { connect } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../layout/table";
import { table as getClients } from "../../actions/client";
import { format as formatDate } from "../../utils/date";
import { only as numbersOnly } from "../../utils/number";
import { phone } from "../../utils/string";
import ConditionalButton from "../../layout/conditional-button";

class Clients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "lastName",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            archived: 0,
            converted: 0,
            past: 0,
            active: 0
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }


    fetchData = async () => {
        const __data = await this.props.getClients(this.state.page, this.state.size, this.state.past, this.state.active, this.state.order, this.state.direction, this.state.search);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Name",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onNameLinkFormat
            },
            {
                header: "Email",
                sortable: true,
                source: "Person.email",
                className: "text-muted text-small text-uppercase w-25",
                onFormat: this.onLinkFormat
            },
            {
                header: "Phone",
                sortable: true,
                source: "Person.Phone.number",
                className: "text-muted text-small text-uppercase w-25",
                onFormat: this.onPhoneLinkFormat
            },
            {
                header: "Created",
                sortable: true,
                source: "createdAt",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onDateFormat
            },
            {
                header: "Updated",
                sortable: true,
                source: "updatedAt",
                className: "text-muted text-small text-uppercase w-10",
                onFormat: this.onDateFormat
            }
        ]
    }

    onNameLinkFormat = (e, row) => {
        const balanceCheck = Number(row["trusts"]) - Number(row["balance"]) - Number(row["clocks"]) - Number(row["expenses"]) < 0 ?
            <span style={{color: "red"}} title="insufficient trust balance">&#x1F3F2;</span> : <></>;
        const pendingCheck = Number(row["clocks"]) + Number(row["expenses"]) + Number(row["payments"]) > 0 ?
            <span style={{color: "green"}} title="unbilled transactions">&#x1F3F2;</span> : <></>;
        if (row["Person.uuid"]) {
            return <a href={`/dashboard/clients/${row['uuid']}`}>{row["Person.lastName"]}, {row["Person.firstName"]} {balanceCheck} {pendingCheck}</a>
        } else {
            return <a href={`/dashboard/clients/${row['uuid']}`}>{row["Company.name"]} d/b/a {row["Company.dba"]} {balanceCheck} {pendingCheck}</a>
        }
            
    }

    onDateFormat = (e) => {
        return formatDate(e, true);
    }

    onLinkFormat = (e, row) => {
        return `<a href="/dashboard/clients/${row['uuid']}">${e ? e : ""}</a>`
    }

    onPhoneLinkFormat = (e, row) => {
        let __phone = numbersOnly(e);
        return `<a href="tel:+${__phone}">${phone(__phone) ? phone(__phone) : __phone}</a>`
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    onNewClick = (e) => {
        this.props.history.push("/dashboard/clients/add");
    }


    onPastChange = (e) => {
        this.setState({
            ...this.state,
            past: this.state.past===1 ? 0 : 1
        }, this.fetchData);
    }

    render() {
        return (
            <>
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Create new client" display enabled={true} onEnabledClick={this.onNewClick} icon="flash">New</ConditionalButton>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                        onSearch={this.onSearch}
                        onSize={this.onSize}
                        searchable
                        sizable
                        filter={(
                            <Row className="mb-3">
                                <Col sm="12" md="6">
                                    <div className="d-inline-block">
                                        <Form.Check className="form-check float-end mt-1" type="checkbox" checked={this.state.past} onChange={this.onPastChange} />
                                        <Form.Label style={{marginLeft: "10px", marginTop: "6px", marginRight: "10px"}}>Include Past Clients</Form.Label>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    />
                </Col>
            </>
        )
    }
}

Clients.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        items: state.licensee.clients
    });
};

export default connect(mapStateToProps, { getClients })(Clients);