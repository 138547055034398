import api from "../utils/api";
import { LOGOUT } from "../actions/types";

export const list = (category) => async (dispatch) => {
    try {
        const res = await api.get(`/codes/c/${category}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const activities = (category) => async (dispatch) => {
    try {
        const res = await api.get(`/codes/activities/c/${category}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}