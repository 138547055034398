import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import { Route, Switch } from "react-router-dom";

import Header from '../../layout/header';
import BreadcrumbList from "../../layout/breadcrumbs";
import pages from "../../constants/menu";
import breadcrumbs, { find } from "../../layout/breadcrumbs/calculate";
import Layout from "../../layout";
import SummaryViewer from "../summary";
import ProfileEditor from "../profile";
import LeadsViewer from "../leads";
import LeadsEditor from "../leads/detail";
import ClientsViewer from "../clients";
import ClientsEditor from "../clients/detail";
import ReportsViewer from "../reports";
import ReportDetail from "../reports/detail";
import LedgerViewer from "../ledger";
import LedgerDetail from "../ledger/detail";

class Dashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            page: find(window.location.pathname, pages),
        }
    }

    componentDidUpdate(oldProps) {

    }

    get title() {
        return this.props.page?.title?.trim().length ? this.props.page?.title : this.state.page.title;
    }


    render() {
        return (
            <>
                <Layout>
                    <Header title={`${this.props.profile?.dashboardTitleBase} | ${this.title}`} description={`${this.props.profile?.dashboardTitleBase} | ${this.state.page.description}`} />

                    {/* Title and Top Buttons Start */}
                    <div className="page-title-container">
                        <Row>
                            {/* Title Start */}
                            <Col md="4">
                                <h1 className="mb-0 pb-0 display-4">{this.title}</h1>
                                <BreadcrumbList items={breadcrumbs(window.location.pathname, pages)} title={ this.props.page.breadcrumb } />
                            </Col>
                            <Switch>
                                <Route exact path="/dashboard" component={SummaryViewer}></Route>
                                <Route exact path="/dashboard/profile/me" component={ProfileEditor}></Route>
                                <Route exact path="/dashboard/leads" component={LeadsViewer}></Route>
                                <Route exact path="/dashboard/leads/:uuid" component={LeadsEditor}></Route>
                                <Route exact path="/dashboard/clients" component={ClientsViewer}></Route>
                                <Route exact path="/dashboard/clients/:uuid" component={ClientsEditor}></Route>
                                <Route exact path="/dashboard/reports" component={ReportsViewer}></Route>
                                <Route exact path="/dashboard/reports/:uuid" component={ReportDetail}></Route>
                                <Route exact path="/dashboard/ledger" component={LedgerViewer}></Route>
                                <Route exact path="/dashboard/ledger/:uuid" component={LedgerViewer}></Route>
                            </Switch> 
                        </Row>
                        <Row>

                        </Row>
                    </div>
                    {/* Title and Top Buttons End */}
                    
                </Layout>
            </>
        );
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        page: state.page,
    });
};

export default connect(mapStateToProps)(Dashboard);